import React, { useState, useEffect, useRef } from "react";
import { configuration } from "../../../helpers/config";
import { useSelector,useDispatch,shallowEqual } from "react-redux";

export default function CustomDropdown({
  value,
  onChange,
  handleSelectChange,
  options,
  error,
  id,
  placeholder,
  label,
  isSubmitting,
  setdealerconsentadress,
}) {
  const {is_dealer_selected,selected_dealer} =useSelector((state)=>state.app);
  console.log("isDealerSelected in forms",is_dealer_selected,"selected_dealer",selected_dealer);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(is_dealer_selected && selected_dealer && selected_dealer?.name?selected_dealer?.name:"-Választott márkakereskedés-");
  const [border, setBorder] = useState(false);
  const [selectedOption, setSelectedOption] = useState(is_dealer_selected && selected_dealer && selected_dealer?.index?selected_dealer?.index:0);
  const focusRef = useRef();
//add rank in each addressLevel in each option
// const addressWiseRank=configuration.ADDRESSLEVEL_ORDER;
const addressWiseRank=JSON.parse(process.env.NEXT_PUBLIC_ADDRESS_LEVEL_ORDER);

options=options.map((option,index)=>{
option={...option,addressLevel1:{...option[addressWiseRank[0]]},addressLevel2:{...option[addressWiseRank[1]]},addressLevel3:{...option[addressWiseRank[2]]},addressLevel4:{...option[addressWiseRank[3]]},addressLevel5:{...option[addressWiseRank[4]]}}
return option;
})
  useEffect(() => {
    if(open) {
      focusRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start"
      })
    }
    
},[open])

  const toggleDropdown = () => {
    setOpen((op) => !op);
    setBorder(true);
  };

  const handleSelectBoxBorder = () => {
    setOpen(false);
    setBorder(false);
  };

  const handlePlaceHolder = () => {
    onChange("");
    setName(placeholder);
    setSelectedOption(0)
    setOpen(false)
  }

  const handleOptions = (e, option) => {
    handleSelectChange(e);
    onChange(JSON.stringify(option));
    setOpen(false);
    setName(
      option.name +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_1 === "true" &&
        option.addressLevel1
          ? ` - ${option.addressLevel1.name}`
          : "") +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_2 === "true" &&
        option.addressLevel2
          ? ` - ${option.addressLevel2.name}`
          : "") +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_3 === "true" &&
        option.addressLevel3
          ? ` - ${option.addressLevel3.name}`
          : "") +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_4 === "true" &&
        option.addressLevel4
          ? ` - ${option.addressLevel4.name}`
          : "") +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_5 === "true" &&
        option.addressLevel5
          ? ` - ${option.addressLevel5.name}`
          : "")
    );
  }
  return (
    <div style={isSubmitting ? { pointerEvents: 'none' } : {}} className="custom-dropdown" onMouseLeave={handleSelectBoxBorder}>
      <div className="label-text">{label}</div>
      <div style={error ? { border: "2px solid #FF9494" } : {}}  className={`selected-option ${border ? "select-border" : ""}`} onClick={toggleDropdown}>
        {name}
      </div>
      {error && <p className="error">{error}</p>}
      {open && (
        <div className="dropdown-options">
          <div className="option-default" onClick={handlePlaceHolder}>
            {placeholder}
          </div>
          {options.map((option, index) => (
              <div
                key={option.name}
                id={id}
                className="option"
                onClick={(e) => {
                handleOptions(e, option);
                setSelectedOption(index + 1);
                setdealerconsentadress(option);
                }}
                onMouseLeave={(e) => handleSelectChange(e)}
              style={
                selectedOption === index + 1
                  ? { backgroundColor: "lightgray", color: "#000" }
                  : {}
              }
                ref={selectedOption === index + 1 ? focusRef : undefined}
              >
                {option.name}
                {configuration.SHOW_DEALER_ADDRESS.LEVEL_1 == "true" &&
                option?.addressLevel1?.name
                  ? ` - ${option.addressLevel1?.name}`
                  : ""}
                {configuration.SHOW_DEALER_ADDRESS.LEVEL_2 == "true" &&
                option?.addressLevel2?.name
                  ? ` - ${option.addressLevel2?.name}`
                  : ""}
                {configuration.SHOW_DEALER_ADDRESS.LEVEL_3 == "true" &&
                option?.addressLevel3?.name
                  ? ` - ${option.addressLevel3?.name}`
                  : ""}
                {configuration.SHOW_DEALER_ADDRESS.LEVEL_4 == "true" &&
                option?.addressLevel4?.name
                  ? ` - ${option.addressLevel4?.name}`
                  : ""}
                {configuration.SHOW_DEALER_ADDRESS.LEVEL_5 == "true" &&
                option?.addressLevel5?.name
                  ? ` - ${option.addressLevel5?.name}`
                  : ""}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}