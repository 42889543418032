import Link from "next/link";
import React from "react";
import { configuration } from "../helpers/config";
// component to render different types of button based on type .

// CTA'S FOR WEBSITE
export default function LinkButton({
  type,
  buttontext,
  href,
  widthFull,
  Id,
  target,
  onClick,
  hideBorder,
  targets,
  fontManage,
  widthManage,
  btnWidth,
  iconImage,
  propsArrow,
}) {
  // check type to render specific button
  switch (type) {
    case "button_dark_arrow":
      return (
        <Link href={href ? href : ""} download passHref>
          <a
            onClick={onClick}
            id={Id}
            className={`button_dark_arrow group ${
              hideBorder && "border-0"
            } ${widthManage}`}
            target={target}
          >
            <span
              className={`text-mobileMenu md:text-default font-suzukiBold group-hover:border-b-primary-blue normal-case ${fontManage}`}
              id={Id}
              style={{ color: fontManage?.color, fontSize: fontManage?.font }}
            >
              {buttontext}
            </span>
            <button
              type="button"
              id={Id}
              className="group-hover:bg-primary-blue"
              aria-label={"blue-arrow"}
              role="button"
            >
              <img
                src={`${configuration.IMG_STATIC_URL}assets/images/blue-arrow.svg`}
                style={{height:"15px",width:"15px"}}
                alt="blue-arrow"
              />
            </button>
          </a>
        </Link>
      );

    case "button_catalogue_arrow":
      return (
        <Link href={href ? href : ""} download passHref>
          <a
            onClick={onClick}
            target={target}
            className={`button_dark_arrow group ${
              hideBorder && "border-0"
            } ${widthManage}`}
          >
            <span
              className={`text-mobileMenu md:text-default font-suzukiBold group-hover:border-b-primary-blue normal-case ${fontManage}`}
              style={{ color: fontManage?.color, fontSize: fontManage?.font }}
            >
              {buttontext}
            </span>
            <button
              type="button"
              className="group-hover:bg-primary-blue"
              aria-label={"blue-arrow"}
              role="button"
            >
              <img src={propsArrow} alt="blue-arrow" style={{height:"15px",width:"15px"}}/>
            </button>
          </a>
        </Link>
      );
    case "button_dark_arrow_button":
      return (
        <button
          className="button_dark_arrow"
          id={Id}
          onClick={onClick}
          style={{ maxWidth: widthManage }}
          aria-label={buttontext}
          role="button"
        >
          <span className="normal-case">{buttontext}</span>
          <div className="border-b-0 button_dark_arrow--text btn-drive">
            <img
              src={`${configuration.IMG_STATIC_URL}assets/images/blue-arrow.svg`}
              style={{height:"15px",width:"15px"}}
              alt="blue-arrow"
            />
          </div>
        </button>
      );
    case "button_light_arrow":
      return (
        <Link href={href ? href : ""} download passHref>
          <a
            target={target}
            rel="noreferrer"
            className={`button_light_arrow group ${widthFull}`}
          >
            <span
              style={{ color: fontManage?.color, fontSize: fontManage?.font }}
              className={`text-mobileMenu md:text-default font-suzukiBold group-hover:border-b-inverted normal-case`}
            >
              {buttontext}
            </span>
            <button type="button">
              <img
                src={`${configuration.IMG_STATIC_URL}assets/images/white-arrow.svg`}
                style={{height:"15px",width:"15px"}}
                alt="white-arrow"
              />
            </button>
          </a>
        </Link>
      );

    case "social__icon--twitter":
      return (
        <a href={href} target="_blank" rel="noreferrer" id={Id}>
          <div className="button_dark_arrow group" id={Id}>
            <span
              className="normal-case group-hover:border-b-primary-blue md:text-default"
              id={Id}
            >
              {buttontext}
            </span>
            <button
              id={Id}
              type="button"
              className="group-hover:bg-primary-blue"
              aria-label={buttontext}
              role="link"
            >
              <img
                src={`${configuration.IMG_STATIC_URL}assets/images/twitterX.svg`}
                style={{ height: "20px", width: "20px" }}
                alt="twitter"
              />
            </button>
          </div>
        </a>
      );

    case "social__icon--linkedin":
      return (
        <a href={href} target="_blank" rel="noreferrer" id={Id}>
          <div className="button_dark_arrow group" id={Id}>
            <span
              className="normal-case group-hover:border-b-primary-blue md:text-default"
              id={Id}
            >
              {buttontext}
            </span>
            <button
              id={Id}
              type="button"
              className="group-hover:bg-primary-blue"
              style={{ padding: "0" }}
              aria-label={buttontext}
              role="link"
            >
              <img
                src={`${configuration.IMG_STATIC_URL}assets/images/linkedin.svg`}
                style={{ height: "20px", width: "20px" }}
                alt="linkedin"
              />
            </button>
          </div>
        </a>
      );
    case "social__icon--facebook":
      return (
        <a href={href} target="_blank" rel="noreferrer" id={Id}>
          <div className="button_dark_arrow group" id={Id}>
            <span
              className="normal-case group-hover:border-b-primary-blue md:text-default"
              id={Id}
            >
              {buttontext}
            </span>
            <button
              id={Id}
              type="button"
              className="group-hover:bg-primary-blue"
              style={{ padding: "0" }}
              role="link"
              aria-label={buttontext}
            >
              <img
                src={`${configuration.IMG_STATIC_URL}assets/images/facebook.svg`}
                style={{ height: "20px", width: "20px" }}
                alt="facebook"
              />
            </button>
          </div>
        </a>
      );

    case "social__icon--instagram":
      return (
        <a href={href} target="_blank" rel="noreferrer" id={Id}>
          <div className="button_dark_arrow group" id={Id}>
            <span
              className="normal-case group-hover:border-b-primary-blue md:text-default"
              id={Id}
            >
              {buttontext}
            </span>
            <button
              id={Id}
              type="button"
              className="group-hover:bg-primary-blue"
              style={{ padding: "0" }}
              aria-label={buttontext}
              role="link"
            >
              <img
                src={`${configuration.IMG_STATIC_URL}assets/images/instagram.svg`}
                style={{ height: "20px", width: "20px",maxWidth:"max-content" }}
                alt="instagram"
              />
            </button>
          </div>
        </a>
      );
    case "button_slide_arrow_button":
      return (
        // <button className={`button_dark_arrow ${btnWidth}`}>
        <button className={`button_dark_arrows ${btnWidth}`}>
          <span className="normal-case">{buttontext}</span>
          <div className="border-b-0 button_dark_arrow--text btn-drive px-0 justify-end w-[30px]">
            <img src={iconImage} alt="" />
          </div>
        </button>
      );
    case "social__icon--youtube":
      return (
        <a href={href} target="_blank" rel="noreferrer" id={Id}>
          <div className="button_dark_arrow group" id={Id}>
            <span
              className="normal-case group-hover:border-b-primary-blue md:text-default"
              id={Id}
            >
              {buttontext}
            </span>
            <button
              id={Id}
              type="button"
              className="group-hover:bg-primary-blue"
              style={{ padding: "0" }}
              aria-label={buttontext}
              role="link"
            >
              <img
                src={`${configuration.IMG_STATIC_URL}assets/images/youtube.svg`}
                style={{ height: "20px", width: "20px" }}
                alt="youtube"
              />
            </button>
          </div>
        </a>
      );

    case "social__icon--tiktok":
      return (
        <a href={href} target="_blank" rel="noreferrer" id={Id}>
          <div className="button_dark_arrow group" id={Id}>
            <span
              className="normal-case group-hover:border-b-primary-blue md:text-default"
              id={Id}
            >
              {buttontext}
            </span>
            <button
              id={Id}
              type="button"
              className="group-hover:bg-primary-blue"
              style={{ padding: "0" }}
              aria-label={buttontext}
              role="link"
            >
              <img
                src={`${configuration.IMG_STATIC_URL}assets/images/tiktok.svg`}
                style={{ height: "20px", width: "20px" }}
                alt="tiktok"
              />
            </button>
          </div>
        </a>
      );
  }
}
