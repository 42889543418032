import React, { useEffect, useState } from "react";
import { configuration } from "../helpers/config";
import { Loader } from "@googlemaps/js-api-loader";
import { dealerData as dealerDataApi } from "./../services/api/findDealer";
import axios from "axios";
import { Constants } from "../helpers/constants";
import Geocode from "react-geocode";
import { useSelector } from "react-redux";

Geocode.setApiKey(configuration.MAPS_API_KEY);
Geocode.enableDebug();

export default function LocationSearchInput({ onUpdate, lob }) {
  const [currentLat, setCurrentLat] = useState("");
  const [currentLng, setCurrentLng] = useState("");
  const [radius, setRadius] = useState(configuration.DEFAULT_MAP_RADIUS);
  const [currentAddress, setCurrentAddress] = useState("");
  const [distanceClicked,setDistanceClicked] = useState(false)
  const reduxData = useSelector(({ app }) => app);
  const [isError, setIsError] = useState(false);
  const LobValue=useSelector(state=>state?.app?.LobValue)

  useEffect(() => {}, [currentAddress]);
  const distanceChange = (e) => {
    setRadius(e.target.value);
    setDistanceClicked(true);
    };

  const getAddressFromLatLng = (latitude, longitude) => {
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setCurrentAddress(address);
      },
      (error) => {
        // console.error(error.message);
        setCurrentAddress("");
      },
    );
  };

  function searchInput() {
    const input = document.getElementById("locationSearchInput");
    const options1 = {
      fields: ["formatted_address", "geometry", "icon", "name"],
      componentRestrictions: { country: configuration.COUNTRY_CODE },
      strictBounds: false,
      types: ['(cities)'],
    };

    const autocomplete = new google.maps.places.Autocomplete(input, options1);
    autocomplete.addListener("place_changed", function () {
      var place = autocomplete.getPlace();
      setCurrentAddress(place?.formatted_address);
      if (place.geometry != undefined) {
        setCurrentLat(place.geometry.location.lat());
        setCurrentLng(place.geometry.location.lng());
      }
    });
  }

  useEffect(() => {
    const getDealerData = async () => {
      let response = await dealerDataApi(lob);
      if (reduxData.is_dealer_selected) {
        setRadius(configuration.DEALER_RADIUS.RADIUS_6);
      } else {
        setRadius(
          response?.data?.dealerRadius
            ? response?.data?.dealerRadius
            : configuration.DEFAULT_MAP_RADIUS,
        );
      }
      setCurrentLat(response?.data?.latitude);
      setCurrentLng(response?.data?.longitude);
      const loader = new Loader({
        apiKey: configuration.MAPS_API_KEY,
        version: "weekly",
        libraries: ["places"],
      });
      loader.load().then(() => {
        searchInput();
      });

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, (err) => {
          showError(err, response.data),
          { enableHighAccuracy: true };
        });
      }
    };
    getDealerData();
  }, []);

  function showPosition(position) {
    let crd = position.coords;
    setCurrentLat(Number(crd.latitude));
    setCurrentLng(Number(crd.longitude));
    getAddressFromLatLng(Number(crd.latitude), Number(crd.longitude));
  }

  function showError(error, responseData = {}) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setCurrentLat(
          Number(responseData?.latitude) || configuration.COUNTRY_DEFAULT_LAT,
        );
        setCurrentLng(
          Number(responseData?.longitude) || configuration.COUNTRY_DEFAULT_LONG,
        );
        break;
      case error.POSITION_UNAVAILABLE:
        setCurrentLat(
          Number(responseData?.latitude) || configuration.COUNTRY_DEFAULT_LAT,
        );
        setCurrentLng(
          Number(responseData?.longitude) || configuration.COUNTRY_DEFAULT_LONG,
        );
        break;
      case error.TIMEOUT:
        setCurrentLat(
          Number(responseData?.latitude) || configuration.COUNTRY_DEFAULT_LAT,
        );
        setCurrentLng(
          Number(responseData?.longitude) || configuration.COUNTRY_DEFAULT_LONG,
        );
        break;
      case error.UNKNOWN_ERROR:
        setCurrentLat(
          Number(responseData?.latitude) || configuration.COUNTRY_DEFAULT_LAT,
        );
        setCurrentLng(
          Number(responseData?.longitude) || configuration.COUNTRY_DEFAULT_LONG,
        );
        break;
    }
  }

  useEffect(() => {
    // if(new_form_data && new_form_data.length > 0){
    //       //  onUpdate(new_form_data);
    // }else{
    (async () => {
      try {
        if (currentLat && currentLng) { 
          const res = await axios.get(
            `${configuration.DEALER_URL_ALT}?longitude=${currentLng}&latitude=${currentLat}&radius=${radius}&lob=${LobValue}&status=true`,
            configuration.HEADER_DEALER_LOCATOR,
          );
          if(!reduxData?.is_dealer_selected || distanceClicked){
            if(res.data.data.length > 0){
            onUpdate(res.data.data);
            }
            else{
              if(radius == configuration.DEALER_RADIUS.RADIUS_1) setRadius(configuration.DEALER_RADIUS.RADIUS_2)
              else if(radius == configuration.DEALER_RADIUS.RADIUS_2) setRadius(configuration.DEALER_RADIUS.RADIUS_3)
              else if(radius == configuration.DEALER_RADIUS.RADIUS_3) setRadius(configuration.DEALER_RADIUS.RADIUS_4)
              else if(radius == configuration.DEALER_RADIUS.RADIUS_4) setRadius(configuration.DEALER_RADIUS.RADIUS_5)
              else if(radius == configuration.DEALER_RADIUS.RADIUS_5) setRadius(configuration.DEALER_RADIUS.RADIUS_6)
            }
          }
        }
      } catch (e) {}
    })();
    // }
  }, [currentLng, currentLat, radius]);

  return (
    <div>
      <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap justify-between items-center mb-[6px]">
        <p className="md:m-0 mb-2">
          {Constants.searchInputLocation.LOCATION}: &nbsp;
        </p>
        <ul className="flex gap-3 text-[13px]">
          <li>
            <input
              id="car-sales"
              className="hidden form__radius-button--checked"
              type="radio"
              name="car-sales"
              value={configuration.DEALER_RADIUS.RADIUS_1}
              onChange={distanceChange}
              checked={configuration.DEALER_RADIUS.RADIUS_1 == radius}
            />
            <label
              htmlFor="car-sales"
              className={`${
                radius == configuration.DEALER_RADIUS.RADIUS_1 && "underline"
              } fradio cursor-pointer`}
            >
              {configuration.DEALER_RADIUS.RADIUS_1}
              {configuration.DEFAULT_DISTANCE_UNIT}
            </label>
          </li>
          <li>
            <input
              id="car-sales1"
              className="hidden fradio"
              type="radio"
              name="car-sales"
              value={configuration.DEALER_RADIUS.RADIUS_2}
              onChange={distanceChange}
              checked={configuration.DEALER_RADIUS.RADIUS_2 == radius}
            />
            <label
              htmlFor="car-sales1"
              className={`${
                radius == configuration.DEALER_RADIUS.RADIUS_2 && "underline"
              } fradio cursor-pointer`}
            >
              {configuration.DEALER_RADIUS.RADIUS_2}
              {configuration.DEFAULT_DISTANCE_UNIT}
            </label>
          </li>
          <li>
            <input
              id="car-sales2"
              className="hidden fradio"
              type="radio"
              name="car-sales"
              value={configuration.DEALER_RADIUS.RADIUS_3}
              onChange={distanceChange}
              checked={configuration.DEALER_RADIUS.RADIUS_3 == radius}
            />
            <label
              htmlFor="car-sales2"
              className={`${
                radius == configuration.DEALER_RADIUS.RADIUS_3 && "underline"
              } fradio cursor-pointer`}
            >
              {configuration.DEALER_RADIUS.RADIUS_3}
              {configuration.DEFAULT_DISTANCE_UNIT}
            </label>
          </li>
          <li>
            <input
              id="car-sales3"
              className="hidden fradio"
              type="radio"
              name="car-sales"
              value={configuration.DEALER_RADIUS.RADIUS_4}
              onChange={distanceChange}
              checked={configuration.DEALER_RADIUS.RADIUS_4 == radius}
            />
            <label
              htmlFor="car-sales3"
              className={`${
                radius == configuration.DEALER_RADIUS.RADIUS_4 && "underline"
              } fradio cursor-pointer`}
            >
              {configuration.DEALER_RADIUS.RADIUS_4}
              {configuration.DEFAULT_DISTANCE_UNIT}
            </label>
          </li>
          <li>
            <input
              id="car-sales4"
              className="hidden fradio"
              type="radio"
              name="car-sales"
              value={configuration.DEALER_RADIUS.RADIUS_5}
              onChange={distanceChange}
              checked={configuration.DEALER_RADIUS.RADIUS_5 == radius}
            />
            <label
              htmlFor="car-sales4"
              className={`${
                radius == configuration.DEALER_RADIUS.RADIUS_5 && "underline"
              } fradio cursor-pointer`}
            >
              {configuration.DEALER_RADIUS.RADIUS_5}
              {configuration.DEFAULT_DISTANCE_UNIT}
            </label>
          </li>
          <li>
            <input
              id="car-sales5"
              className="hidden fradio"
              type="radio"
              name="car-sales"
              value={configuration.DEALER_RADIUS.RADIUS_6}
              onChange={distanceChange}
              checked={configuration.DEALER_RADIUS.RADIUS_6 == radius}
            />
            <label
              htmlFor="car-sales5"
              className={`${
                radius == configuration.DEALER_RADIUS.RADIUS_6 && "underline"
              } fradio cursor-pointer`}
            >
              {Constants.findDealerPage.ALL}{" "}
            </label>
          </li>
        </ul>
      </div>
      <div className="flex group block min-w-[100%] max-w-[100%] font-suzukiRegular h-[50px] leading-[50px]  rounded-none outline-none  text-[14px] border-[1px] border-[#C0C0C0]">
        <input
          type="text"
          placeholder={Constants.searchInputLocation.LOCATION}
          className="w-full pl-[16px] px-2 outline-none  "
          id="locationSearchInput"
          name="locationSearchInput"
          value={currentAddress}
          onChange={(e) => {
            const pattern = /^[^<>]*$/;
            if (pattern.test(e.target.value)) {
              setCurrentAddress(e.target.value);
              setIsError(false);
            } else {
              setIsError(true);
            }
          }}
        />
        {/* <button
          type="button"
          className="border-b-0 group-hover:bg-primary-blue group-hover:text-inverted px-3"
        >
          <img
            src={`${configuration.IMG_STATIC_URL}assets/images/blue-search.svg`}
            className="group-hover:invert"
            alt=""
          />
        </button> */}
      </div>
    </div>
  );
}