export const configuration = {
  DEALER_RADIUS: {
    RADIUS_1: process.env.NEXT_PUBLIC_DEALER_RADIUS_ONE,
    RADIUS_2: process.env.NEXT_PUBLIC_DEALER_RADIUS_TWO,
    RADIUS_3: process.env.NEXT_PUBLIC_DEALER_RADIUS_THREE,
    RADIUS_4: process.env.NEXT_PUBLIC_DEALER_RADIUS_FOUR,
    RADIUS_5: process.env.NEXT_PUBLIC_DEALER_RADIUS_FIVE,
    RADIUS_6: process.env.NEXT_PUBLIC_DEALER_RADIUS_SIX,
  },
  SHOW_DEALER_RADIUS: {
    RADIUS_1: process.env.NEXT_PUBLIC_SHOW_DEALER_RADIUS_ONE,
    RADIUS_2: process.env.NEXT_PUBLIC_SHOW_DEALER_RADIUS_TWO,
    RADIUS_3: process.env.NEXT_PUBLIC_SHOW_DEALER_RADIUS_THREE,
    RADIUS_4: process.env.NEXT_PUBLIC_SHOW_DEALER_RADIUS_FOUR,
    RADIUS_5: process.env.NEXT_PUBLIC_SHOW_DEALER_RADIUS_FIVE,
    RADIUS_6: process.env.NEXT_PUBLIC_SHOW_DEALER_RADIUS_SIX,
  },
  SHOW_DEALER_ADDRESS: {
    LEVEL_1: process.env.NEXT_PUBLIC_SHOW_DEALER_ADDRESS_ONE,
    LEVEL_2: process.env.NEXT_PUBLIC_SHOW_DEALER_ADDRESS_TWO,
    LEVEL_3: process.env.NEXT_PUBLIC_SHOW_DEALER_ADDRESS_THREE,
    LEVEL_4: process.env.NEXT_PUBLIC_SHOW_DEALER_ADDRESS_FOUR,
    LEVEL_5: process.env.NEXT_PUBLIC_SHOW_DEALER_ADDRESS_FIVE
   },

   

  CMP_FLAG: process.env.NEXT_PUBLIC_CMP_FLAG,
  IS_ROUTE_GUARD_IMPLENTED: process.env.NEXT_PUBLIC_IS_ROUTE_GUARD_IMPELMENTED,
  COUNTRY_DEFAULT_LAT: process.env.NEXT_PUBLIC_COUNTRY_DEFAULT_LAT,
  COUNTRY_DEFAULT_LONG: process.env.NEXT_PUBLIC_COUNTRY_DEFAULT_LONG,
  COUNTRY_CODE: process.env.NEXT_PUBLIC_COUNTRY_CODE,
  SHOW_SEARCH: process.env.NEXT_PUBLIC_SHOW_SEARCH,
  DEFAULT_MAP_RADIUS: process.env.NEXT_PUBLIC_DEFAULT_MAP_RADIUS,
  DEFAULT_DISTANCE_UNIT: process.env.NEXT_PUBLIC_DEFAULT_DISTANCE_UNIT,
  HEADER_DEALER_LOCATOR: {
    headers: { "x-api-key": process.env.NEXT_PUBLIC_X_API_KEY },
  },
  LOCATION_SEARCH_URL: process.env.NEXT_PUBLIC_LOCATION_SEARCH_URL,
  DOMAIN_NAME: process.env.NEXT_PUBLIC_DOMAIN_NAME,
  APP_URL_CONTENTS: process.env.NEXT_PUBLIC_APP_URL_CONTENTS,
  APP_URL_CONTENTS_LB: process.env.NEXT_PUBLIC_APP_URL_CONTENTS_LB,
  APP_URL_PRODUCTS: process.env.NEXT_PUBLIC_APP_URL_PRODUCTS,
  FORM_URL: process.env.NEXT_PUBLIC_FORM_URL,
  FORM_API_KEY: process.env.NEXT_PUBLIC_FORM_API_KEY,
  FORM_COUNTRY_CODE: process.env.NEXT_PUBLIC_FORM_COUNTRY_CODE,
  PASS_THROUGH_BEHAVIOR: process.env.NEXT_PUBLIC_PASS_THROUGH_BEHAVIOR,
  REQ_TYPE: process.env.NEXT_PUBLIC_REQ_TYPE,
  IMG_URL: process.env.NEXT_PUBLIC_IMG_URL,
  IMG_STATIC_URL:process.env.NEXT_PUBLIC_STATIC_IMG_URL,
  IMG_STATIC_URL1:process.env.NEXT_PUBLIC_STATIC_IMG_URL1,
  COMMON_MASTER_API: process.env.NEXT_PUBLIC_COMMON_MASTER_API,
  DEALER_URL: process.env.NEXT_PUBLIC_DEALER_URL,
  DEALER_URL_ALT: process.env.NEXT_PUBLIC_DEALER_URL_ALT,
  COMMON_MASTER_LOB: process.env.NEXT_PUBLIC_COMMON_MASTER_LOB,
  COMMON_MASTER_LOB_2W: process.env.NEXT_PUBLIC_COMMON_MASTER_LOB_2W,
  COMMON_MASTER_LOB_MARINE: process.env.NEXT_PUBLIC_COMMON_MASTER_LOB_MARINE,
  COMMON_MASTER_SUZUKI: process.env.NEXT_PUBLIC_COMMON_MASTER_SUZUKI,
  COMMON_MASTER_KEY: process.env.NEXT_PUBLIC_COMMON_MASTER_KEY,
  DEALER_CODE: process.env.NEXT_PUBLIC_DEALER_CODE,
  PREVIEW_BASE_URL: process.env.NEXT_PUBLIC_PREVIEW_BASE_URL,
  UPDATE_CONSENT_BASE_URL: process.env.NEXT_PUBLIC_UPDATE_CONSENT_BASE_URL,
  UPDATE_CONSENT_PRIVACY_POLICY_LINK:process.env.NEXT_PUBLIC_UPDATE_CONSENT_PRIVACY_POLICY_LINK,
  UPDATE_CONSENT_HEADER: {
    "x-api-key": process.env.NEXT_PUBLIC_UPDATE_CONSENT_API_KEY,
  },
  UPDATE_CONSENT_MODAL_ALLOWED:
    process.env.NEXT_PUBLIC_UPDATE_CONSENT_MODAL_ALLOWED,
  MAPS_API_KEY: process.env.NEXT_PUBLIC_MAPS_API_KEY,
  STATIC_ROUTES: process.env.NEXT_PUBLIC_STATIC_ROUTES,
  CMP_FLAG_SCRIPT: process.env.NEXT_PUBLIC_CMP_FLAG_SCRIPT,
  SHOW_TNC_FIND_SUZUKI: process.env.NEXT_PUBLIC_SHOW_TNC_FIND_SUZUKI,
  VARIANT_GROUPING_TYPE: process.env.NEXT_PUBLIC_VARIANT_GROUPING_TYPE,
  FLOAT_BAR_DEALER: process.env.NEXT_PUBLIC_FLOAT_BAR_DEALER,
  FLOAT_BAR_GET_A_QUOTE: process.env.NEXT_PUBLIC_FLOAT_BAR_GET_A_QUOTE,
  FLOAT_BAR_TEST_DRIVE: process.env.NEXT_PUBLIC_FLOAT_BAR_TEST_DRIVE,
  FLOAT_BAR_BROCHURE: process.env.NEXT_PUBLIC_FLOAT_BAR_BROCHURE,
  FLOAT_BAR_ENQUIRY: process.env.NEXT_PUBLIC_FLOAT_BAR_ENQUIRY,
  FLOAT_BAR_BOOK_SERVICE: process.env.NEXT_PUBLIC_FLOAT_BAR_BOOK_SERVICE,
  FLOAT_BAR_CONTACT_US: process.env.NEXT_PUBLIC_FLOAT_BAR_CONTACT_US,
  CAR_CONFIGURATOR_FORM_MANDATORY: process.env.NEXT_PUBLIC_IS_FORM_MANDATORY,
  FLOAT_BAR_DEALER_2W: process.env.NEXT_PUBLIC_FLOAT_BAR_DEALER_2W,
  FLOAT_BAR_GET_A_QUOTE_2W: process.env.NEXT_PUBLIC_FLOAT_BAR_GET_A_QUOTE_2W,
  FLOAT_BAR_TEST_DRIVE_2W: process.env.NEXT_PUBLIC_FLOAT_BAR_TEST_DRIVE_2W,
  FLOAT_BAR_BROCHURE_2W: process.env.NEXT_PUBLIC_FLOAT_BAR_BROCHURE_2W,
  FLOAT_BAR_ENQUIRY_2W: process.env.NEXT_PUBLIC_FLOAT_BAR_ENQUIRY_2W,
  FLOAT_BAR_BOOK_SERVICE_2W: process.env.NEXT_PUBLIC_FLOAT_BAR_BOOK_SERVICE_2W,
  FLOAT_BAR_CONTACT_US_2W: process.env.NEXT_PUBLIC_FLOAT_BAR_CONTACT_US_2W,
  VIEW_CMP_HISTORY: process.env.NEXT_PUBLIC_VIEW_CMP_HISTORY,
  SOCIAL_ICON: process.env.NEXT_PUBLIC_SOCIAL_ICON,
  API_LOB: process.env.NEXT_PUBLIC_API_LOB,
  USERNAME:process.env.NEXT_PUBLIC_USERNAME,
  PASSWORD:process.env.NEXT_PUBLIC_PASSWORD,
  USER_FIRST_NAME:process.env.NEXT_PUBLIC_USER_FIRST_NAME,
  USER_SECOND_NAME:process.env.NEXT_PUBLIC_USER_LAST_NAME,
  ENSIGHTEN : process.env.NEXT_PUBLIC_ENSIGHTEN,
  COOKIEBOT : process.env.NEXT_PUBLIC_COOKIEBOT,
  RESEND_OTP_IN_UPDATE_CONSENT_ALLOWED:
    process.env.NEXT_PUBLIC_RESEND_OTP_IN_UPDATE_CONSENT_ALLOWED,
  SHOW_PARALLAX : process.env.NEXT_PUBLIC_SHOW_PARALLAX,
  ADDRESSLEVEL_ORDER: process.env.NEXT_PUBLIC_ADDRESS_LEVEL_ORDER ,
  TRIGGER_SAVE_DATA_API_IN_BROCHURE_FORM : process.env.NEXT_PUBLIC_TRIGGER_SAVE_DATA_API_IN_BROCHURE_FORM,
  MIN_PHONE_NUMBER_LENGTH:process.env.NEXT_PUBLIC_MIN_PHONE_NUMBER_LENGTH
};
export const AWS_URL = process.env.NEXT_PUBLIC_AWS_URL;
export const MODEL_DETAILS = process.env.NEXT_PUBLIC_MODEL_DETAILS;
export const CRYPTO = {
  SECRET: process.env.NEXT_PUBLIC_SECRET,
  IV: process.env.NEXT_PUBLIC_IV,
  ALGORITHM: process.env.NEXT_PUBLIC_ALGORITHM,
};
