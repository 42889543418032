import Layout from "../components/layout/layout";
import "../styles/globals.css";
import "../styles/unreset.css";
import ErrorBoundary from "../components/ErrorBoundary";
import { RouteGuard } from "../components/auth/RouteGuard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect } from "react";
import Loader from "../components/Loader/Loader";
import { Router, useRouter } from "next/router";
import { Provider, useSelector } from "react-redux";
import store from "../redux/store";
import AcceptCookies from "../components/acceptCookies";
import { configuration } from "../helpers/config";
import useGoogleTranslateFix from "../hooks/useGoogleTranslateFix";

function MyApp({ Component, pageProps }) {
  const [loading, setLoading] = React.useState(false);
  const router = useRouter();
  useGoogleTranslateFix()

  useEffect(() => {
    const { asPath } = router;

    if (asPath !== asPath.toLowerCase()) {
      router.push(asPath.toLowerCase());
    }
  }, [router]);

  React.useEffect(() => {
    const handleRouteChange = () => {
      window.scrollTo(0, 0);
    };
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };
    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeComplete", handleRouteChange);
    Router.events.on("routeChangeError", end);
    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, []);
  return (
    <ErrorBoundary>
      <ToastContainer />
      {loading && <Loader centerMode />}
      <Provider store={store}>
        {configuration.IS_ROUTE_GUARD_IMPLENTED == "true" ? (
          <RouteGuard>
            <Layout menu={pageProps.Menudata } footer={pageProps.Footerdata} >
              <Component {...pageProps} />
            </Layout>
          </RouteGuard>
        ) : (
          <Layout menu={pageProps.Menudata } footer={pageProps.Footerdata} >
            <Component {...pageProps} />
          </Layout>
        )}
        {/* {(!configuration.COUNTRY_CODE == "IN" || "PH") &&
        configuration.CMP_FLAG == "false" ? (
          <AcceptCookies />
        ) : null} */}

        {(!configuration?.COOKIEBOT?.includes(configuration.COUNTRY_CODE) &&
          !configuration?.ENSIGHTEN?.includes(configuration.COUNTRY_CODE)) ||
        (configuration?.COOKIEBOT?.includes(configuration.COUNTRY_CODE) &&
          configuration?.ENSIGHTEN?.includes(configuration.COUNTRY_CODE)) ? (
          <AcceptCookies />
        ) : null}
      </Provider>
    </ErrorBoundary>
  );
}

export default MyApp;
