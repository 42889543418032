import React, { forwardRef } from "react";
// import ToolTip from "../Tool-tip";
import { Tooltip } from "react-tooltip";
import Styles from "./Forms.module.css";
import Style from "./Forms.module.css";
import styles from "../Tool-tip/tool-tip.module.css";
import Image from "next/image";
import {configuration} from "../../../helpers/config"

// InputField component accepts various props for customization
// eslint-disable-next-line react/display-name
const InputField = forwardRef(
  (
    {
      error,
      label,
      required,
      name,
      mandatory,
      showTooltip,
      imageVar,
      tooltipContent,
      iconSource,
      descript,
      minLength,
      maxLength,
      srcIcon,
      showIcons,
      ...props
    },
    ref,
  ) => {
    return (
      <div className="">
        {/* Container for the input field and label */}
        <div className="label-icon flex justify-between items-center relative ">
          {/* Label for the input field */}
          {label && (
            <label className={Styles.label} htmlFor={name}>
              {label}
              {required && "*"}
            </label>
          )}

          {/* ToolTip - Commented out, but can be used */}
          {/* <ToolTip
            infoIcon = {`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            toolImg = {`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoTip.svg`}
            desc = {props.desc}
            toolposition = {props.toolposition}
            arrowPosition = {props.arrowPosition}
          /> */}

          {/* Display tooltip if showTooltip is true */}
          {showTooltip && (
            <>
              <div className="tool-tip-img">
                {/* Tooltip icon */}
                <Image
                  data-tooltip-id={name}
                  src={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
                  height="15"
                  width="15"
                />
              </div>
              <Tooltip
                id={name}
                src={imageVar}
                variant="light"
                className="shadow-lg z-10"
              >
                <div className="tool-tip-wrap flex items-center justify-between">
                  <div className="w-[20px] flex items-center mr-1 ">
                    {/* Icon inside the tooltip */}
                    <Image src={iconSource} width={15} height={15} />
                  </div>
                  {/* Tooltip description */}
                  <p className={styles.toolTip_desc}>{descript}</p>
                </div>
              </Tooltip>
            </>
          )}
        </div>

        {/* Input field */} 
        <div className="relative">
        <div
        className={`absolute pointer-events-none customPlaceholder`}
      />
      {(configuration.COUNTRY_CODE == 'RO' || configuration.COUNTRY_CODE == 'HUN') && props?.type === "remarks" ?
        <input
          className={error ? Styles.inputError : Styles.input}
          style={{width: "207%"}} 
          minLength={minLength}
          maxLength={maxLength}
          name={name}
          required={mandatory}
          {...props}
          ref={ref}
      />
       
      : 
      <input
        className={error ? Styles.inputError : Styles.input}
        minLength={minLength}
        maxLength={maxLength}
        name={name}
        required={mandatory}
        {...props}
        ref={ref}
      /> 
      }

        {showIcons && (
          <div className="fieldIcon">
            <Image src={srcIcon} height="18" width="18" priority={true} />
          </div>
        )}
        </div>

        {/* Display error message if error exists */}
        <p className={Style.error}>{error}</p>
      </div>
    );
  },
);

export default InputField;
