import {
  TOGGLE_ENQUIRY_FORM,
  TOGGLE_ENQUIRY_FORM_2W,
  TOGGLE_FINANCE_FORM,
  TOGGLE_BROCHURE_FORM,
  TOGGLE_TEST_DRIVE_FORM,
  TOGGLE_SERVICE_FORM,
  TOGGLE_CONTACT_FORM,
  DEALER_DATA_FORM,
  IS_DEALER_SELECTED,
  SELECTED_DEALER,
  TOGGLE_GET_QUOTE_FORM,
  CAR_CONFIGURATOR_SUBMIT,
  PERSONAL_IDENTIFIABLE_INFORMATION_FORM,
  CONFIGURATOR_DATA,
  SEND_MAIL_DEALER_CONFIGURATOR_DATA,
  TOGGLE_GET_QUOTE_FORM_2W,
  TOGGLE_TEST_DRIVE_FORM_2W,
  TOGGLE_BROCHURE_FORM_2W,
  TOGGLE_SERVICE_FORM_2W,
  PERSONAL_IDENTIFIALBLE_FORM_RES,
  TOGGLE_IS_LOB_PAGE, UPDATE_LOB_VALUE,
} from "./ActionTypes";

const initialState = {
  formModals: {
    testDrive: false,
    brochure: false,
    enquiry: false,
    finance: false,
    service: false,
    contact: false,
    getQuote: false,
    pii: false,
    send_to_dealer_configurator: false,
    testDrive_2w: false,
    brochure_2w: false,
    enquiry_2w: false,
    finance_2w: false,
    service_2w: false,
    contact_2w: false,
    getQuote_2w: false,
  },
  dealers: [],
  is_dealer_selected: false,
  selected_dealer: null,
  is_car_configurator_submit: false,
  personal_identifiable_form_response: {},
  configurator_data: {},
  isLobPage: false,
  LobValue: null
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ENQUIRY_FORM:
      return {
        ...state,
        formModals: { ...state.formModals, enquiry: !state.formModals.enquiry },
      };
    case TOGGLE_ENQUIRY_FORM_2W:
      return {
        ...state,
        formModals: {
          ...state.formModals,
          enquiry_2w: !state.formModals.enquiry_2w,
        },
      };
    case TOGGLE_FINANCE_FORM:
      return {
        ...state,
        formModals: { ...state.formModals, finance: !state.formModals.finance },
      };
    case TOGGLE_BROCHURE_FORM:
      return {
        ...state,
        formModals: {
          ...state.formModals,
          brochure: !state.formModals.brochure,
        },
      };
    case TOGGLE_BROCHURE_FORM_2W:
      return {
        ...state,
        formModals: {
          ...state.formModals,
          brochure_2w: !state.formModals.brochure_2w,
        },
      };
    case TOGGLE_TEST_DRIVE_FORM:
      return {
        ...state,
        formModals: {
          ...state.formModals,
          testDrive: !state.formModals.testDrive,
        },
      };
    case TOGGLE_TEST_DRIVE_FORM_2W:
      return {
        ...state,
        formModals: {
          ...state.formModals,
          testDrive_2w: !state.formModals.testDrive_2w,
        },
      };
    case TOGGLE_SERVICE_FORM:
      return {
        ...state,
        formModals: {
          ...state.formModals,
          service: !state.formModals.service,
        },
      };
    case TOGGLE_SERVICE_FORM_2W:
      return {
        ...state,
        formModals: {
          ...state.formModals,
          service_2w: !state.formModals.service_2w,
        },
      };
    case TOGGLE_CONTACT_FORM:
      return {
        ...state,
        formModals: {
          ...state.formModals,
          contact: !state.formModals.contact,
        },
      };
    case TOGGLE_GET_QUOTE_FORM:
      return {
        ...state,
        formModals: {
          ...state.formModals,
          getQuote: !state.formModals.getQuote,
        },
      };
    case PERSONAL_IDENTIFIABLE_INFORMATION_FORM:
      return {
        ...state,
        formModals: {
          ...state.formModals,
          pii: !state.formModals.pii,
          // testDrive: !state.formModals.testDrive,
        },
      };
    case SEND_MAIL_DEALER_CONFIGURATOR_DATA: {
      return {
        ...state,
        formModals: {
          ...state.formModals,
          send_to_dealer_configurator:
            !state.formModals.send_to_dealer_configurator,
        },
      };
    }
    case CONFIGURATOR_DATA: {
      return {
        ...state,
        configurator_data: action.payload,
      };
    }
    case PERSONAL_IDENTIFIALBLE_FORM_RES: {
      return {
        ...state,
        personal_identifiable_form_response: action.payload,
      };
    }
    case TOGGLE_GET_QUOTE_FORM_2W:
      return {
        ...state,
        formModals: {
          ...state.formModals,
          getQuote_2w: !state.formModals.getQuote_2w,
        },
      };
    case TOGGLE_ENQUIRY_FORM_2W:
      return {
        ...state,
        formModals: {
          ...state.formModals,
          enquiry_2w: !state.formModals.enquiry_2w,
        },
      };
    case DEALER_DATA_FORM:
      return {
        ...state,
        dealers: action.payload,
      };
    case IS_DEALER_SELECTED: {
      return {
        ...state,
        is_dealer_selected: action.payload,
      };
    }
    case SELECTED_DEALER: {
      return {
        ...state,
        selected_dealer: action.payload,
      };
    }
    case CAR_CONFIGURATOR_SUBMIT: {
      return {
        ...state,
        is_car_configurator_submit:action.payload}}
    case TOGGLE_IS_LOB_PAGE: {
      return {
        ...state,
        isLobPage: action.payload,
      };
    }
    case UPDATE_LOB_VALUE : {
      return {
        ...state,
        LobValue: action.payload
      }
    }
    case "TOGGLE_DYNAMIC_FORM":
      return {
        ...state,
        formModals: {
          ...state.formModals,
          [action.payload]: !state.formModals[action.payload], // Dynamically toggle form
        },
        };
    default:
      return state;
  }
};

export default AppReducer;
