import React, { forwardRef } from "react";
import Image from "next/future/image";

//component to display brand logo in website.
// eslint-disable-next-line react/display-name
const BrandLogo = forwardRef(
  ({ className, logoSrc, height, width, href ,Id}, ref) => {
    return (
      <a ref={ref} href={href} id={Id}>
        <div className="brand-logo_container">
          <Image
            className={`${className} brand-logo`}
            src={logoSrc}
            alt="Suzuki"
            fill
            layout="fill"
          />
        </div>
      </a>
    );
  },
);
export default BrandLogo;
