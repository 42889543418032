import { configuration } from "../helpers/config";
import { Constants } from "../helpers/constants";
import moment from "moment";
import axios from "axios";

const NEXT_PUBLIC_CURRENCY_IDENTIFIER_AT_SUFFIX =
  process.env.NEXT_PUBLIC_CURRENCY_IDENTIFIER_AT_SUFFIX;
const NEXT_PUBLIC_CURRENCY_IDENTIFIER_IN_STRING =
  process.env.NEXT_PUBLIC_CURRENCY_IDENTIFIER_IN_STRING;

export function parseHTML(str) {
  if (typeof window !== "undefined") {
    const tmp = document?.implementation.createHTMLDocument("");
    tmp.body.innerHTML = str;
    return tmp.body?.firstChild?.wholeText;
  } else {
    return str;
  }
}

export function formatCurrency(value) {
  const options = {
    style: "currency",
    currency: Constants.currency,
    minimumFractionDigits: 0,
  };

  return NEXT_PUBLIC_CURRENCY_IDENTIFIER_AT_SUFFIX === "true"
    ? NEXT_PUBLIC_CURRENCY_IDENTIFIER_IN_STRING === "true"
      ? configuration?.COUNTRY_CODE=="HUN"? new Intl.NumberFormat(Constants.locale, options).format(value)
      .replace(/\s(?=\d{3})/g, ".") // Replace spaces with dots before groups of three digits
      .replace(/(\.\d{3})\.$/, "$1") // Remove any trailing dot
      .replace("Ft", " Ft") : new Intl.NumberFormat(Constants.locale, options).format(value) +
        " " +
        Constants.currency
      :configuration?.COUNTRY_CODE=="HUN"? new Intl.NumberFormat(Constants.locale, options).format(value)
      .replace(/\s(?=\d{3})/g, ".") // Replace spaces with dots before groups of three digits
      .replace(/(\.\d{3})\.$/, "$1") // Remove any trailing dot
      .replace("Ft", " Ft") : new Intl.NumberFormat(Constants.locale, options).format(value)
    : NEXT_PUBLIC_CURRENCY_IDENTIFIER_IN_STRING === "true"
    ? Constants.currency +
      " " +
      configuration?.COUNTRY_CODE=="HUN"? new Intl.NumberFormat(Constants.locale, options).format(value)
      .replace(/\s(?=\d{3})/g, ".") // Replace spaces with dots before groups of three digits
      .replace(/(\.\d{3})\.$/, "$1") // Remove any trailing dot
      .replace("Ft", " Ft") : new Intl.NumberFormat(Constants.locale, options).format(value)
    : configuration?.COUNTRY_CODE=="HUN"? new Intl.NumberFormat(Constants.locale, options).format(value)
    .replace(/\s(?=\d{3})/g, ".") // Replace spaces with dots before groups of three digits
    .replace(/(\.\d{3})\.$/, "$1") // Remove any trailing dot
    .replace("Ft", " Ft") : new Intl.NumberFormat(Constants.locale, options).format(value);
}

export const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

export const today = new Date();
today.setDate(today.getDate());

export const tomorrowDate = tomorrow.toISOString().split("T")[0];
export function getImageDimensions(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = function () {
      const height = img.height;
      const width = img.width;
      resolve({ height, width });
    };

    img.onerror = function () {
      reject(new Error("Failed to load image"));
    };

    img.src = url;
  });
}
export function formatDateByCountryCode(date) {
  let formatString = " ";

  switch (configuration.COUNTRY_CODE) {
    case "PH":
      formatString = "DD MMMM YYYY";
      break;
    case "US":
      formatString = "MM/DD/YYYY";
      break;
    case "IN":
      formatString = "DD/MM/YYYY";
      break;
    case "AU":
      formatString = "DD/MM/YYYY";
      break;
    default:
      formatString = "DD-MM-YYYY";
      break;
  }

  return moment(date).format(formatString);
}

export const formatCategoriesAndHighlights = (highlights, categories) => {
  const categorizedHighlights = {};

  categorizedHighlights["all"] = {
    categoryId: "all",
    categoryName: "Összes",
    highlights: [...highlights],
  };

  highlights.forEach((highlight) => {
    highlight.categories.forEach((categoryId) => {
      const category = categories.find(
        (category) => category._id === categoryId,
      );

      if (category) {
        if (!categorizedHighlights[categoryId]) {
          categorizedHighlights[categoryId] = {
            categoryId: categoryId,
            categoryName: category.name,
            highlights: [],
          };
        }

        categorizedHighlights[categoryId].highlights.push(highlight);
      }
    });
  });

  return Object.values(categorizedHighlights);
};
export function validateUrl(str) {
  let tarea = str?.split("://");
  if (tarea?.indexOf("http") == 0 || tarea?.indexOf("https") == 0) {
    return true;
  }
  let wwwUrl = str?.split(".");
  if (wwwUrl?.indexOf("www") == 0) {
    return true;
  }
  return false;
}

export function removeUndefinedKeys(arr) {
  // Use the filter method to create a new array with only defined values
  return arr.filter(function (value) {
    // Keep the value in the new array only if it is not undefined
    return value !== undefined;
  });
}


// export async function homePageFetcher(props, lob) {
//   try {
//     const response = await fetch(
//         `${configuration.APP_URL_CONTENTS}/home-page?lob=${lob}`,
//     );
//     const data = await response.json();
//     const cmsResponse = await fetch(
//         `${configuration.APP_URL_CONTENTS}/cms-pages?lob=${lob}`,
//     );
//     const datacms = await cmsResponse.json();
//     const cmsPage = data[0]?.placeholder || [];
//     let resultPage = [];
//     cmsPage?.forEach((e) => {
//       let tempPages = [];
//       e.pages?.forEach((el) => {
//         datacms?.forEach((ele) => {
//           if (el._id == ele._id) {
//             tempPages.push(ele);
//           }
//         });
//       });
//       resultPage.push({
//         ...e,
//         pages: tempPages,
//       });
//     });
//     data[0].placeholder = resultPage;
//     props.data = data;
//   } catch (err) {
//     console.warn(err, "Error Data");
//     props.error = true;
//   }
//   return props;
// }


export async function homePageFetcher(props, lob) {
  try {
    const response = await fetch(
      `${configuration.APP_URL_CONTENTS}/home-page?lob=${lob}`
    );
    const data = await response.json();

    const cmsResponse = await fetch(
      `${configuration.APP_URL_CONTENTS}/cms-pages?lob=${lob}`
    );
    const datacms = await cmsResponse.json();

    if (data && data[0]) {
      const cmsPage = data[0]?.placeholder;
      let resultPage = [];

      cmsPage?.forEach((e) => {
        let tempPages = [];
        e.pages?.forEach((el) => {
          datacms?.forEach((ele) => {
            if (el._id === ele._id) {
              tempPages.push(ele);
            }
          });
        });
        resultPage.push({
          ...e,
          pages: tempPages,
        });
      });

      data[0].placeholder = resultPage;
      props.data = data;
    } else {
      props.data = null;
    }
  } catch (err) {
    console.warn(err, "Error Data");
    props.error = true;
  }

  return props;
}


export async function BrandPageFetcher( brand, props, lob) {
  try {
    const res = await axios.get(
        `${configuration.APP_URL_PRODUCTS}model/${brand}?lob=${lob}`,
    );
    const { data } = res;
    const cars = await axios.get(
        `${configuration.APP_URL_PRODUCTS}model?lob=${lob}`,
    );
    const cmsResponse = await axios.get(
        `${configuration.APP_URL_CONTENTS}/cms-pages?lob=${lob}`,
    );
    
    const configuratorData = await axios.get(
      `${configuration.APP_URL_PRODUCTS}configurator/${data?.data?.model_code}`,
    );
    
    let allCarsData = cars.data.data;
    let allCmsData = cmsResponse.data;


    const topicsData = data?.data?.car_topic
        ? data?.data?.car_topic
            ?.filter((product) => {
              return allCmsData.some((other) => other._id === product.car_id);
            })
            .map((product) => {
              const { car_id, status } = product;
              const other = allCmsData.find((other) => other._id === car_id);
              return { car_id, status, ...other };
            })
        : [];

    const linkedData = data?.data?.car_product
        ? data?.data?.car_product
            ?.filter((product) => {
              return allCarsData.some((other) => other._id === product.car_id);
            })
            .map((product) => {
              const { car_id, status } = product;
              const other = allCarsData.find((other) => other._id === car_id);
              return { car_id, status, ...other };
            })
        : [];

 //data sorting
 let result = data.data.variants?.sort(
  (a, b) => a.order_index - b.order_index,
)
const resdata = await axios.get(
 `${configuration.APP_URL_PRODUCTS}model/compare/${data?.data?.model_code}?v1=${result[0]?.variant_code}&v2=${result[1]?.variant_code}&v3=${result[2]?.variant_code}`,
);  
let sortedPerformance =[];
let sortedFeatures  = [];
if(resdata)
{
     // sorting performance data of the variants
sortedPerformance = [...resdata.data?.performance].sort(
(a, b) => parseInt(a.order_index) - parseInt(b.order_index),
);
// sorting features data of the variants
sortedFeatures = [...resdata.data?.features].sort(
(a, b) => parseInt(a.order_index) - parseInt(b.order_index),
);
}

    if (data.data) {
      return {
        props: {
          data: {
            ...data.data,
            car_product: linkedData,
            car_topic: topicsData,
            configuratorData: configuratorData?.data?.data || {},
            // sortedPerformance:sortedPerformance,
            // sortedFeatures,sortedFeatures

          },
        },
      };
    } else {
      return {
        props: {
          error: true,
        },
      };
    }
  } catch (err) {
    console.warn(err, "Error Fetching Brand Page Data");
    return {
      props: {
        error: true,
      },
    };
  }
}

export function formatDateTime(isoString) {
  const date = new Date(isoString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function redirectRoute(link,routeToBeRedirected,redirectedTo){
  try{
    let newUrl=link;
    const fullUrl = link;

    // Create a URL object
    const url = new URL(fullUrl);
    
    // Extract the base URL (protocol + hostname + port)
    const baseUrl = `${url.protocol}//${url.hostname}${url.port ? `:${url.port}` : ''}`;
    
    // Extract the path
    let path = url.pathname;
    if(path==routeToBeRedirected){
      newUrl= baseUrl + redirectedTo;
    }else{
      return link;
    }
    
    
  }catch(err){
    console.log("error in redirectRoute,",err?.message)
  }
}

export function IsValidPrice(price){
  return price && price !=null &&  !isNaN(price) && typeof price !="undefined" && price !="undefined";
}