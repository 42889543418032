const {configuration}=require("./../../helpers/config")
const usersData=[
    {
        "id": 1,
        "username": configuration.USERNAME,
        "password": configuration.PASSWORD,
        "firstName": configuration.USER_FIRST_NAME,
        "lastName": configuration.USER_SECOND_NAME
    }
]

export default usersData