import React, { useEffect, useState } from "react";
import Image from "next/image";
import {configuration} from "../helpers/config";

export default function BackToTop() {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    // Add a scroll event listener when the component mounts
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        // Check if the user has scrolled more than 400 pixels down
        setShowTopBtn(true); // If yes, set showTopBtn state to true
      } else {
        setShowTopBtn(false); // Otherwise, set showTopBtn state to false
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0, // scroll to top
      behavior: "smooth", // scroll smoothly
    });
  };
  return (
    <>
    <div className="backToTop__btn2">
    <h2>BETA</h2>
    </div>
      {showTopBtn && (
        <button className="backToTop__btn" onClick={goToTop}>
          <Image
            src={`${configuration.IMG_STATIC_URL}assets/images/white-arrow.svg`}
            height={17}
            width={16}
            alt=""
          />
        </button>
      )}
    </>
  );
}
