import "react-responsive-modal/styles.css";
import React, { useEffect, useState } from "react";
import FloatBar from "../floating-menu/float-bar";
import Footer from "./footer";
import Header from "./header";
import axios from "axios";
import { configuration } from "../../helpers/config";
import { useDispatch, useSelector } from "react-redux";
import { formNames } from "../forms/Validation";
import { toggleForm } from "../../redux/ActionCreator";
import { useRouter } from "next/router";
import DynamicForm from "../forms/dynamic-form/dynamic-form";
import {
  IS_DEALER_SELECTED,
  SELECTED_DEALER,
  PERSONAL_IDENTIFIABLE_INFORMATION_FORM,
} from "../../redux/ActionTypes";

import Script from "next/script";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import Head from "next/head";

// export default function Layout({ children }, props) {
const Layout = ({ children, menu, footer }) => {
  const formModals = useSelector(({ app }) => app.formModals);
  const isLobPage = useSelector(({ app }) => app.isLobPage);
  const dispatch = useDispatch();
  const router = useRouter();
  const [data, setData] = useState([]);
  const [formsData, setFormsData] = useState([]);
  const [scriptdata, setscriptdata] = useState(null);
  const [noscriptdata, setnoscriptdata] = useState(null);
  const EXTERNAL_DATA_URL = process.env.NEXT_PUBLIC_DOMAIN_NAME;
  const currentUrl = EXTERNAL_DATA_URL + router.asPath;
  const LOB_VALUE = useSelector((state) => state?.app?.LobValue);

  // fetch forms data on mounting of page
  useEffect(() => {
    if (!LOB_VALUE) return;
    (async () => {
      try {
        const res = await axios.get(
          `${configuration.APP_URL_CONTENTS}/forms?lob=${LOB_VALUE?LOB_VALUE:"1"}`,
        );
        setFormsData(res.data[0]?.forms);
      } catch (e) {
        // console.log(e.message);
      }
    })();
  }, [LOB_VALUE]);

  // fetch settings config from API on mounting
  useEffect(() => {
    if (!LOB_VALUE) return;
    (async () => {
      try {
        const res = await axios.get(
          `${configuration.APP_URL_CONTENTS}/settings?lob=${LOB_VALUE}`,
        );
        setData(res.data);
      } catch (err) {
        // console.log(err.message);
      }
    })();
  }, [LOB_VALUE]);

  //get data from s3 bucket for script in head.js
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${configuration.IMG_URL}js/head.js`);
        const result = await response.json();
        setscriptdata(result);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    })();
  }, []);

  // get data from s3 bucket for noscript in body.js
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${configuration.IMG_URL}js/body.js`);
        const result = await response.json();
        setnoscriptdata(result);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    })();
  }, []);

  useEffect(() => {
    if (typeof document !== "undefined") {
      noscriptdata && noscriptdata.length > 0
        ? document.body.insertAdjacentHTML("afterbegin", noscriptdata)
        : null;
    }
  }, [noscriptdata]);
  const ManageCookieComponent = () => {
    return (
      <>
       <Script src={configuration.CMP_FLAG_SCRIPT} />
      </>
    );
  };

  return (
    <>
      <Head>
      {scriptdata && scriptdata.length > 0 ? (
          <script
            dangerouslySetInnerHTML={{
              __html: scriptdata,
            }}
          />
        ) : null}
        {configuration?.COOKIEBOT?.includes(configuration?.COUNTRY_CODE) && !configuration?.ENSIGHTEN.includes(configuration?.COUNTRY_CODE) && (
        <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="377d214f-d206-4851-9f57-ea23d9bb0b6a"  type="text/javascript"></script>
 
        )}
 
        
        <link rel="canonical" href={currentUrl} />
         {process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED=="true"|| process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED==true && <link rel="preconnect" href="//d3rs4ckmrsvep5.cloudfront.net" />}
        { process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED=="true"|| process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED==true && <link rel="preconnect" href="//d2lmjolkxrort.cloudfront.net" />}
       { process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED=="true"|| process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED==true && <script
          src="https://d3rs4ckmrsvep5.cloudfront.net/js/environment.js"
          async={true}
        ></script>
       }
      </Head>
      <body>
        {noscriptdata && noscriptdata.length > 0 ? (
          <noscript
            dangerouslySetInnerHTML={{
              __html: noscriptdata,
            }}
          />
        ) : null}
      </body>
      <div>
        {/*// all form components with their respective conditions */}
        {formModals.testDrive && (
          <DynamicForm
            open={formModals.testDrive}
            onClose={() => {
              dispatch(toggleForm(formNames.TEST_DRIVE));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            //filter specific form data from all forms data
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.TEST_DRIVE,
                  )[0]
                : []
            }
          />
        )}

        {formModals.testDrive_2w && (
          <DynamicForm
            open={formModals.testDrive_2w}
            onClose={() => {
              dispatch(toggleForm(formNames.TEST_DRIVE_2W));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.TEST_DRIVE_2W,
                  )[0]
                : []
            }
          />
        )}
        {formModals.brochure && (
          <DynamicForm
            open={formModals.brochure}
            onClose={() => {
              dispatch(toggleForm(formNames.BROCHURE));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            is_share_brochure="is_share_brochure"
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.BROCHURE,
                  )[0]
                : []
            }
          />
        )}
        {formModals.brochure_2w && (
          <DynamicForm
            open={formModals.brochure_2w}
            onClose={() => {
              dispatch(toggleForm(formNames.BROCHURE_2W));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            is_share_brochure="is_share_brochure"
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.BROCHURE_2W,
                  )[0]
                : []
            }
          />
        )}

        {formModals.finance && (
          <DynamicForm
            open={formModals.finance}
            onClose={() => {
              dispatch(toggleForm(formNames.CALC_PAYMENT));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.CALC_PAYMENT,
                  )[0]
                : []
            }
          />
        )}

        {formModals.enquiry && (
          <DynamicForm
            open={formModals.enquiry}
            onClose={() => {
              dispatch(toggleForm(formNames.ENQUIRY));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.ENQUIRY,
                  )[0]
                : []
            }
          />
        )}

        {formModals.enquiry_2w && (
          <DynamicForm
            open={formModals.enquiry_2w}
            onClose={() => {
              dispatch(toggleForm(formNames.ENQUIRY_2W));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.ENQUIRY_2W,
                  )[0]
                : []
            }
          />
        )}
        {formModals.service && (
          <DynamicForm
            open={formModals.service}
            onClose={() => {
              dispatch(toggleForm(formNames.SERVICE));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.SERVICE,
                  )[0]
                : []
            }
          />
        )}
        {formModals.service_2w && (
          <DynamicForm
            open={formModals.service_2w}
            onClose={() => {
              dispatch(toggleForm(formNames.SERVICE_2W));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.SERVICE_2W,
                  )[0]
                : []
            }
          />
        )}

        {formModals.contact && (
          <DynamicForm
            open={formModals.contact}
            onClose={() => {
              dispatch(toggleForm(formNames.CONTACT_US));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.CONTACT_US,
                  )[0]
                : []
            }
          />
        )}

        {formModals.getQuote && (
          <DynamicForm
            open={formModals.getQuote}
            onClose={() => {
              dispatch(toggleForm(formNames.GET_QUOTE));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.GET_QUOTE,
                  )[0]
                : []
            }
          />
        )}
        {formModals.getQuote_2w && (
          <DynamicForm
            open={formModals.getQuote_2w}
            onClose={() => {
              dispatch(toggleForm(formNames.GET_QUOTE_2W));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.GET_QUOTE_2W,
                  )[0]
                : []
            }
          />
        )}
        {formModals.pii && (
          <DynamicForm
            open={formModals.pii}
            onClose={() => {
              dispatch({ type: PERSONAL_IDENTIFIABLE_INFORMATION_FORM });
              // dispatch({ type: IS_DEALER_SELECTED, payload: false });
              // dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) =>
                      item.form_name ===
                      formNames.PERSONAL_IDENTIFICATION_INFORMATION,
                  )[0]
                : []
            }
          />
        )}
        {formModals.send_to_dealer_configurator && (
          <DynamicForm
            open={formModals.send_to_dealer_configurator}
            onClose={() => {
              dispatch({ type: "send_to_dealer_configurator" });
              // dispatch({ type: IS_DEALER_SELECTED, payload: false });
              // dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.SEND_TO_DEALER,
                  )[0]
                : []
            }
          />
        )}
        {formModals.offer_details && (
          <DynamicForm
            open={formModals.offer_details}
            onClose={() => {
              dispatch(toggleForm(formNames.OFFER_DETAIL));
              dispatch({ type: IS_DEALER_SELECTED, payload: false });
              dispatch({ type: SELECTED_DEALER, payload: null });
            }}
            data={
              formsData
                ? formsData?.filter(
                    (item) => item.form_name === formNames.OFFER_DETAIL,
                  )[0]
                : []
            }
          />
        )}
                {Object.keys(formModals).map((formName) => {
             const predefinedForms = [
               "testDrive", 
               "brochure",
               "enquiry",
               "finance",
               "service",
               "contact",
               "getQuote",
               "pii",
               "send_to_dealer_configurator",
               "testDrive_2w",
               "brochure_2w",
               "enquiry_2w",
               "finance_2w",
               "service_2w",
               "contact_2w",
               "getQuote_2w",
             ];
           
             // Render dynamic forms for non-predefined cases
             if (!predefinedForms.includes(formName)) {
               return (
                 <DynamicForm
                   key={formName}
                   open={formModals[formName]}
                   onClose={() => {
                     dispatch(toggleForm(formName));
                     dispatch({ type: IS_DEALER_SELECTED, payload: false });
                     dispatch({ type: SELECTED_DEALER, payload: null });
                   }}
                   data={
                     formsData
                       ? formsData?.filter((item) => item.form_name === formName)[0]
                       : []
                   }
                 />
               );
             }
             return null;
           }
     
   )
	 	 	 }
        {/* external script for head */}
        {router.pathname !== "/login" && <Header menu={menu}/>}
        {/* <div
          dangerouslySetInnerHTML={{ __html: data[0]?.analytics?.google_tag }}
        /> */}
        <div className="layout__page--container">{children}</div>
        {router.pathname !== "/login" && !isLobPage && (
          <FloatBar formsData={formsData} />
        )}
        {router.pathname !== "/login" && router.pathname !== "/404" &&(
          <Footer  footer={footer} />
        )}
        {/* {router.pathname !== "/login" && !isLobPage && LOB_VALUE !== null && (
          <Footer footer={footer} />
        )} */}
        <Script
          src={`${configuration.IMG_URL}js/body.js`}
          strategy="lazyOnload"
        />
        {/* {configuration.CMP_FLAG == "true" && !isLobPage && configuration.ENSIGHTEN.includes(configuration.COUNTRY_CODE) &&(
          <ManageCookieComponent />
        )} */}
        { !isLobPage && configuration.ENSIGHTEN.includes(configuration.COUNTRY_CODE) && !configuration.COOKIEBOT.includes(configuration.COUNTRY_CODE) &&(
          <ManageCookieComponent />
        )}
        {/* chat plugin html */}

        { process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED=="true"|| process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED==true && <div
          className="dave-main-container"
          id="dave-settings"
          data-dave-components="chatbot-help,js/index.js"
          data-dave-version="9.1"
        ></div>
       }
       { process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED=="true"|| process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED==true && 
        <div
          className="dave-help-container"
          id="dave-help"
          data-timeout={15000}
        ></div>
       }
       { process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED=="true"|| process.env.NEXT_PUBLIC_ISCHATBOT_ALLOWED==true &&
        <script
          src="https://d2lmjolkxrort.cloudfront.net/assets/js/9.1/library-loader.js"
          defer={true}
          async={true}
        ></script>
       }
        {/* {configuration.COOKIEBOT.includes(configuration.COUNTRY_CODE) && !configuration.ENSIGHTEN.includes(configuration.COUNTRY_CODE)&& (
        <script
          id="CookieDeclaration"
          src="https://consent.cookiebot.com/377d214f-d206-4851-9f57-ea23d9bb0b6a/cd.js"
          type="text/javascript"
          async
        ></script>
        )} */}

        <div id="fb-root"></div>
        <div
          className="fb-customerchat"
          attribution="setup_tool"
          page_id="381427860277"
          theme_color="#0084ff"
        ></div>
      </div>
    </>
  );
};

export default Layout;