import { configuration } from "../helpers/config";

export const bike1Data = {
  _id: "63b3eba7086fa000e33ca2cb",
  __v: 0,
  brochure: "1672735648844_JimnyBrochure_compressed.pdf",
  comfortable_equipment: [
    {
      title: "Pack your gear for adventure.",
      desc: "Whether navigating the urban jungle or exploring off the beaten track, the Jimny offers ample space and practical storage features to accommodate every need.",
      image_desktop: "1672736273758_section4_desktop.webp",
      image_mobile: "1672736279733_section4_mobile.webp",
      updated_status: 0,
      status: 0,
      _id: "64539da7b4bdd03c0cb21b9a",
    },
    {
      title: "Front seat",
      desc: "<p>The front seat cushion frames are 70mm wider and 55mm taller seatbacks contribute to better shock absorption. </p>",
      image_desktop: "Front_Seat_OP1_1683020755079.jpg",
      image_mobile: "Front_Seat_OP1_1683020766450.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d9d2a36826fc72a060b",
    },
  ],
  configure: [
    {
      title: "Safeguard yourself and others.",
      desc: "Safety is a priority whether trekking through the wilderness or commuting in the city. Thanks to Suzuki's safety technology, you can always drive with peace of mind.\n",
      image_desktop: "1672736182150_section2_desktop.webp",
      image_mobile: "1672736189222_section2_mobile.webp",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "645276932a36826fc728fea3",
    },
    {
      title: "Dual front airbags",
      desc: "<p> As a supplemental restraint system to the seatbelts, the airbags contribute to mitigating the collision force inflicted on the head and chest.</p>",
      image_desktop: "406_OP_1683019704982.jpg",
      image_mobile: "406_OP_1683019712611.jpg",
      updated_keys: ["title", "desc"],
      updated_status: 0,
      status: 0,
      _id: "645234bbb4bdd03c0cb09c43",
    },
    {
      title: "Electronic stability programme®",
      desc: "<p>If the Electronic Stability Programme (ESP®) detects wheel slippage, it automatically adjust the engine torque and brakes to suppress wheel slippage and help the driver stay in directional control.</p>",
      image_desktop: "435_OP_1683019766981.jpg",
      image_mobile: "435_OP_1683019781439.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "645275be2a36826fc728fe53",
    },
  ],
  createdAt: "2023-01-03T09:00:18.467Z",
  degree_view: {
    title: "Stay focused on what matters most.",
    desc: "In the Jimny, there is little to distract you from driving. Its stylish black interior is humble yet handsome, and the controls are designed for quick and easy operation even when wearing gloves.\n",
    highlights:
      "In the Jimny, there’s little to distract you from driving. Its stylish black interior is humble yet handsome, and controls are designed for quick and easy operation even when wearing gloves.\n",
    html: '<iframe src="https://autodev.suzuki.com.ph/suv/all-new-jimny/threesixty/interior" frameborder="0" scrolling="no" style="width:100%; height:400px"></iframe>',
    cta_text: "XEM THÊM",
    cta_link:
      "/pages/understanding-journeys",
    image_desktop: "1676963614148_section1_desktop2.webp",
    image_mobile: "1676963626003_section1_mobile2.webp",
  },
  deleted_images: [],
  description: "<p><br></p>",
  exterior_degree_view: {
    title: "V-STROM 250SX",
    desc: "Trải nghiệm V-STROM 250SX qua nhiều góc độ khác nhau. Mang trên mình thiết kế đặc trưng của các dòng Adventure, V-STROM 250SX toát lên vẻ ngoài mạnh mẽ, linh hoạt, sẵn sàng chinh phục mọi hành trình.",
    highlights:
      "Với thiết kế gọn gàng và linh hoạt, V-Strom 250SX đặc biệt phù hợp với các chuyến đi du lịch và chuyến đi ngắn, xe còn mang lại trải nghiệm lái tuyệt vời với khả năng vận hành an toàn, hiệu quả.",
    html: `<iframe style="overflow: hidden; height: 100%;" id="vinsearch" src="https://suzuki-world.com.vn/Vstrom250SX/index.html" name="myiFrame" marginwidth="0px" marginheight="0px" scrolling="no" allowfullscreen="allowfullscreen" width="100%" height="100%" frameborder="0"></iframe>`,
    cta_text: "XEM THÊM",
    cta_link:
      "/pages/understanding-journeys",
    image_desktop: "1672924249924_section1_desktop1.webp",
    image_mobile: "1672924257863_section1_mobile1.webp",
  },
  images: {
    desk_small: "1672735679375_product-jimny-kineticyellow.webp",
    desk_medium: "1672735686367_product-jimny-kineticyellow.webp",
    desk_large: "1672735707003_home-1920x738_3.webp",
    desk_xl: "1672735716858_home-1920x738_3.webp",
    desk_banners: ["1672735747875_banner_desktop.webp"],
    mob_small: "1672735804601_product-jimny.webp",
    mob_medium: "1672735810194_product-jimny.webp",
    mob_large: "1672735819303_home-768x564-mob_3.webp",
    mob_xl: "1672735773120_banner_mobile.webp",
    mob_banners: ["jimny.webp_1679987529864"],
  },
  key_features: [],
  mileage: "NAA",
  model_code: "JI",
  model_name: "VSTROM250",
  page_url: "jimny",
  performance: [
    {
      title: "Power your off-road ambitions.",
      desc: "Nothing stands in your way when driving the Jimny. It takes you wherever you want to go with unrivalled agility, precision control and powerful torque when you need it most.",
      image_desktop: "1672736235450_section3_desktop.webp",
      image_mobile: "1672736241716_section3_mobille.webp",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "645276a52a36826fc728fef3",
    },
    {
      title: "4AT (GL/GLX)",
      desc: "<p>The 4-speed automatic transmission has been renewed to match the K15B engine, with refined transmission components that reduces friction and enhanced fuel efficiency.</p>",
      image_desktop: "118_1683019875469.jpg",
      image_mobile: "118_1683019858704.jpg",
      updated_status: 0,
      status: 0,
      _id: "64539d40b4bdd03c0cb21ab8",
    },
    {
      title: "5MT (GL) ",
      desc: "<p>Based on the current model’s 5 speed manual transmission, the new Jimny’s transmission system has optimized gear ratios and better fuel efficiency.</p>",
      image_desktop: "119_1683019890028.jpg",
      image_mobile: "119_1683019899471.jpg",
      updated_keys: ["title", "desc"],
      updated_status: 0,
      status: 0,
      _id: "645235dab4bdd03c0cb09cf9",
    },
    {
      title: "4WD with low range transfer gear ",
      desc: "<p>Part-time 4WD allows switching between 4WD, for optimal off-road performance, and 2WD for better fuel economy and enhanced cornering on paved roads. 4WD low range transfer gear provides extra torque in the 4L mode for rough terrain.</p>",
      image_desktop: "120_OP_1683019987338.jpg",
      image_mobile: "120_OP_1683019963703.jpg",
      updated_status: 0,
      status: 0,
      _id: "64539d55b4bdd03c0cb21ae0",
    },
    {
      title: "Ladder frame ",
      desc: "<p>Compared to a monocoque body, it is more resistant to torsion and better suited for off-road use. With the ladder frame, the driver can drive confidently even on rough terrain.</p>",
      image_desktop: "408_1683020120950.jpg",
      image_mobile: "408_1683020113567.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d60b4bdd03c0cb21b08",
    },
    {
      title: "3-link rigid axle suspension with coil spring",
      desc: "<p>The new Jimny is equipped with long-stroke 3-link suspension that is teamed with rigid full wheel axles on both front and rear to support strong traction on uneven terrain. It’s a far more robust solution than the independent suspensions found on many of today’s SUVs.</p>",
      image_desktop: "412_OP_1683020142977.jpg",
      image_mobile: "412_OP_1683020152999.jpg",
      updated_status: 0,
      status: 0,
      _id: "64539d6ab4bdd03c0cb21b30",
    },
    {
      title: "Three angles",
      desc: "<p>The Jimny can climb over obstacles and steep hills without scrapping its bumpers or underbody, thanks to an ample approach angle (37 deg.), and ramp breakover angle (28 deg.) and departure angle (49 deg.).</p>",
      image_desktop: "414_1_1683020172906.jpg",
      image_mobile: "414_1_1683020164471.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d762a36826fc72a05a6",
    },
    {
      title: "Hill hold control",
      desc: "<p>Move upward and onward when starting on steep slopes. Even on bumpy slopes that vary on the right and left sides, this feature helps prevent rearward rolling so you can focus on controlling acceleration.</p>",
      image_desktop: "418_OP_1683020193775.jpg",
      image_mobile: "418_OP_1683020202994.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d812a36826fc72a05ce",
    },
    {
      title: "Hill descent control",
      desc: "<p>On tricky descents, with a push of a button, the system is designed to automatically apply the brakes and maintain a fixed speed so you can concentrate on steering without using the brake pedal or clutch.</p>",
      image_desktop: "419_OP_1683020281957.jpg",
      image_mobile: "419_OP_1683020270725.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d8bb4bdd03c0cb21b6c",
    },
  ],
  published: 1,
  published_value: 3,
  retail_price: "NA",
  see_details: {
    title: "4WD with low range transfer gear.",
    desc: "At your command, the Jimny adapts to every situation. Let ALLGRIP PRO satisfy your adventurous mind with its on and off-road mastery.\n",
    cta_text: "KNOW MORE",
    cta_link:
      "https://website.suzukibusinesscloud-philippinesuat.com/pages/Jimny-detail",
    image_desktop: "1675416260666_JimnySECTION5(1).jpg",
    image_mobile: "1672736339675_section5_mobile.webp",
  },
  short_description:
    "READY TO CONQUER THE WILD\nBuilt to tackle the harshest\nweather and terrain, the Jimny goes where other vehicles fear to tread. Take on\nnature’s challenges. Unleash your spirit of adventure and get ready to explore..",
  sliders: [],
  status: 0,
  updatedAt: "2023-07-19T14:37:43.687Z",
  updated_images: [
    {
      Key: "Desk_1922X1153Exterior_1683986594465.jpg",
    },
    {
      Key: "Desk765x1920Interior_1683986597357.jpg",
    },
    {
      Key: "fileuploader/Maskgroup_1689579166831.png",
    },
  ],
  updated_status: {
    basic_details: 0,
    images: 0,
    variants: 0,
    features: 0,
    sliders: 0,
    degree_view: 0,
    degree_view_images: 0,
    configure: 0,
    performance: 0,
    see_details: 0,
    key_features: 0,
    comfortable_equipment: 0,
    exterior_degree_view: 0,
    video: 0,
    car_product: 0,
    car_topic: 0,
    menu_list: 0,
    seo_settings: 0,
  },
  variants: [
    {
      variant_name: "VSTROM250",
      variant_code: "JI08",
      fuel_type: "GAS",
      engine_cc: "1500",
      transmission_type: "Manual",
      emission: "Euro 4",
      power: "",
      co2WltpMin: "",
      co2WltpComb: "",
      fuelConsumptionComb: "",
      fuelConsumptionMax: "",
      fuelConsumptionMin: "",
      smcVariantCode: "",
      drive: "",
      grade: "",
      description2: "",
      year: "",
      enquiry: "true",
      gearbox: "",
      propulsion: "",
      status: 2,
      colors: [
        {
          color_name: "VÀNG – ĐEN",
          color_code: "26U",
          hex_code: "#FCED20",
          hex_code2: "#000000",
          image: `${configuration.IMG_STATIC_URL}assets/images/strom/black_gold.png`,
          on_road_price: "132.900.000 VND",
          status: 0,
          is_default: false,
          _id: "645df4d970292a422a1d32ba",
        },
        {
          color_name: "CAM – ĐEN",
          color_code: "Z2S",
          hex_code: "#F9AB9E",
          hex_code2 : "#7F7F7F",
          image: `${configuration.IMG_STATIC_URL}assets/images/strom/Orange_black.png`,
          on_road_price: "132.900.000 VND",
          status: 0,
          is_default: false,
          _id: "645df60d372e859e942ea56f",
        },
        {
          color_name: "ĐEN",
          color_code: "ZJ3",
          hex_code: "#7F7F7F",
          image: `${configuration.IMG_STATIC_URL}assets/images/strom/black.png`,
          on_road_price: "132.900.000 VND",
          status: 0,
          is_default: false,
          _id: "645df6b2372e859e942eabf3",
        },
      ],
      features: {
        "63d77284cba94805f0b09932": {
          value: "1462",
          feature_name: "Chiều dài x rộng x cao",
          feature_type: "1",
          visible: true,
        },
        "63d773cecba94805f0b09934": {
          value: " 130 / 4,000",
          feature_name: "Maximum Torque (Nm/RPM)",
          feature_type: "1",
          visible: true,
        },
        "63d77348cba94805f0b09933": {
          value: " 100 / 6,000",
          feature_name: "Maximum output (HP/RPM)",
          feature_type: "1",
          visible: true,
        },
        "63d77525cba94805f0b09937": {
          value: "210",
          feature_name: "Minimum Ground Clearance (mm)",
          feature_type: "1",
          visible: true,
        },
        "63d77498cba94805f0b09935": {
          value: "4.9",
          feature_name: "Minimum Turning Radius (m)",
          feature_type: "1",
          visible: true,
        },
        "63d775dbcba94805f0b09939": {
          value: " 4 Person",
          feature_name: "Seating Capacity",
          feature_type: "1",
          visible: true,
        },
        "63d76ffbcba94805f0b09931": {
          value: "5-Speed Manual",
          feature_name: "Transmission Type",
          feature_type: "1",
          visible: true,
        },
        "63d77585cba94805f0b09938": {
          value: " 195/80R15",
          feature_name: "Tyres",
          feature_type: "1",
          visible: true,
        },
        "63c8f90f8746411432e23d42": {
          value: "",
          feature_name: "12V Accessory Sockets",
          feature_type: "2",
          visible: false,
        },
        "63c8f8908746411432e23d38": {
          value: "",
          feature_name: "22 Rope Hooks",
          feature_type: "2",
          visible: false,
        },
        "63c8f9e28746411432e23d58": {
          value: "",
          feature_name: "50/50 Split Full Swing Hinges Back Door with Camlock",
          feature_type: "2",
          visible: false,
        },
        "63c8fb8a8746411432e23d7a": {
          value: "",
          feature_name: "ABS",
          feature_type: "2",
          visible: false,
        },
        "63c8f7328746411432e23d20": {
          value: "",
          feature_name: "ABS with EBD",
          feature_type: "2",
          visible: false,
        },
        "63c8f8c38746411432e23d3e": {
          value: "",
          feature_name: "Air Conditioner",
          feature_type: "2",
          visible: false,
        },
        "63c8fb438746411432e23d76": {
          value: "",
          feature_name: "Air Conditioning",
          feature_type: "2",
          visible: false,
        },
        "63c8fab08746411432e23d6c": {
          value: "",
          feature_name: "Air Conditioning with Manual Controls",
          feature_type: "2",
          visible: false,
        },
        "63c8fb978746411432e23d7c": {
          value: "",
          feature_name: "Airbags",
          feature_type: "2",
          visible: false,
        },
        "63c8f9bf8746411432e23d54": {
          value: "",
          feature_name: "Aluminium Rivetless Exterior Panel",
          feature_type: "2",
          visible: false,
        },
        "63c8f8528746411432e23d34": {
          value: "",
          feature_name: "Angle Post",
          feature_type: "2",
          visible: false,
        },
        "63c8f7a18746411432e23d28": {
          value: "",
          feature_name: "Anti-Brake Lock System (ABS)",
          feature_type: "2",
          visible: false,
        },
        "63c8fa508746411432e23d62": {
          value: "",
          feature_name: "Assist Grip",
          feature_type: "2",
          visible: false,
        },
        "63c8f8b18746411432e23d3c": {
          value: "",
          feature_name: "Audio System",
          feature_type: "2",
          visible: false,
        },
        "63c8fb248746411432e23d72": {
          value: '9" Touchscreen w/o Clinometer',
          feature_name: "Audio Unit",
          feature_type: "2",
          visible: true,
        },
        "63c8f8018746411432e23d2e": {
          value: "            -",
          feature_name: "Automatic Lamps",
          feature_type: "2",
          visible: true,
        },
        "63c8f7478746411432e23d22": {
          value: "",
          feature_name: "Break Assist Function",
          feature_type: "2",
          visible: false,
        },
        "63c8f9898746411432e23d4e": {
          value: "",
          feature_name: "Ceiling Grab Handles",
          feature_type: "2",
          visible: false,
        },
        "63c8f6e28746411432e23d1c": {
          value: "",
          feature_name: "Central Door locks",
          feature_type: "2",
          visible: false,
        },
        "63c8faf38746411432e23d6e": {
          value: "",
          feature_name: "Cruise Control",
          feature_type: "2",
          visible: false,
        },
        "63c8f8fd8746411432e23d40": {
          value: "",
          feature_name: "Cup holder",
          feature_type: "2",
          visible: false,
        },
        "63c8fa698746411432e23d66": {
          value: "",
          feature_name: "Defogger",
          feature_type: "2",
          visible: false,
        },
        "63c8fa788746411432e23d68": {
          value: "",
          feature_name: "Door locks",
          feature_type: "2",
          visible: false,
        },
        "63c8f71b8746411432e23d1e": {
          value: "Y",
          feature_name: "Dual Airbags",
          feature_type: "2",
          visible: true,
        },
        "63c8f8a28746411432e23d3a": {
          value: "",
          feature_name: "Electric Power Steering",
          feature_type: "2",
          visible: false,
        },
        "63c8fba98746411432e23d7e": {
          value: "Y",
          feature_name: "Electronic Stability Program (ESP)",
          feature_type: "2",
          visible: true,
        },
        "63c8f83a8746411432e23d32": {
          value: "",
          feature_name: "Engine Auto Start Stop (EASS)",
          feature_type: "2",
          visible: false,
        },
        "63c8fb7c8746411432e23d78": {
          value: "Y",
          feature_name: "Fabric Seats",
          feature_type: "2",
          visible: true,
        },
        "63c8fa128746411432e23d5a": {
          value: "",
          feature_name: "Front and Side Clearance Lamp",
          feature_type: "2",
          visible: false,
        },
        "63c8f8698746411432e23d36": {
          value: "",
          feature_name: "Gate Type",
          feature_type: "2",
          visible: false,
        },
        "63c8f75a8746411432e23d24": {
          value: "",
          feature_name: "Halogen Multi Reflector Headlamps",
          feature_type: "2",
          visible: false,
        },
        "63c8f7ea8746411432e23d2c": {
          value: "Halogen Multi Reflector",
          feature_name: "Headlamps",
          feature_type: "2",
          visible: true,
        },
        "63c8f96a8746411432e23d4c": {
          value: "",
          feature_name: "Heavy duty door locks",
          feature_type: "2",
          visible: false,
        },
        "63c8f8148746411432e23d30": {
          value: "Y",
          feature_name: "Hill Descent Control",
          feature_type: "2",
          visible: true,
        },
        "63c8fbc98746411432e23d82": {
          value: "Y",
          feature_name: "Hill Hold Control",
          feature_type: "2",
          visible: true,
        },
        "63c8f92d8746411432e23d46": {
          value: "",
          feature_name: "Immobilizer",
          feature_type: "2",
          visible: false,
        },
        "63c8f6068746411432e23d12": {
          value: "",
          feature_name: "LED Headlamps",
          feature_type: "2",
          visible: false,
        },
        "63c8f99b8746411432e23d50": {
          value: "",
          feature_name: "Ladder Rack",
          feature_type: "2",
          visible: false,
        },
        "63c8f6718746411432e23d16": {
          value: "",
          feature_name: "Letherette + Fabric Seats",
          feature_type: "2",
          visible: false,
        },
        "63c8f9d08746411432e23d56": {
          value: "",
          feature_name: "Marine Plywood inner panel",
          feature_type: "2",
          visible: false,
        },
        "63c8f6828746411432e23d18": {
          value: "",
          feature_name: "Multi Information Display",
          feature_type: "2",
          visible: false,
        },
        "63c8fb348746411432e23d74": {
          value: "",
          feature_name: "Power Steering",
          feature_type: "2",
          visible: false,
        },
        "63c8f69f8746411432e23d1a": {
          value: "",
          feature_name: "Power Window",
          feature_type: "2",
          visible: false,
        },
        "63c8fbba8746411432e23d80": {
          value: "",
          feature_name: "Power Windows",
          feature_type: "2",
          visible: false,
        },
        "63c8fa238746411432e23d5c": {
          value: "",
          feature_name: "Rear Red Reflector",
          feature_type: "2",
          visible: false,
        },
        "63c8fb118746411432e23d70": {
          value: "",
          feature_name: "Reverse Camera",
          feature_type: "2",
          visible: false,
        },
        "63c8fa5c8746411432e23d64": {
          value: "",
          feature_name: "Reverse Sensor",
          feature_type: "2",
          visible: false,
        },
        "63c8f6518746411432e23d14": {
          value: "",
          feature_name: "Roof Rails",
          feature_type: "2",
          visible: false,
        },
        "63c8f93a8746411432e23d48": {
          value: "",
          feature_name: "Seats",
          feature_type: "2",
          visible: false,
        },
        "63c8fa888746411432e23d6a": {
          value: "",
          feature_name: "Side Mirrors",
          feature_type: "2",
          visible: false,
        },
        "63c8f94b8746411432e23d4a": {
          value: "",
          feature_name: "Sliding Glass Window",
          feature_type: "2",
          visible: false,
        },
        "63c8fa318746411432e23d5e": {
          value: "",
          feature_name: "Speaker",
          feature_type: "2",
          visible: false,
        },
        "63c8f7cd8746411432e23d2a": {
          value: "",
          feature_name: "Special Edition Standard Accessories",
          feature_type: "2",
          visible: false,
        },
        "63c8f91e8746411432e23d44": {
          value: "",
          feature_name: "Steering Lock",
          feature_type: "2",
          visible: false,
        },
        "63c8f9af8746411432e23d52": {
          value: "",
          feature_name: "Tool Box",
          feature_type: "2",
          visible: false,
        },
        "63c8fa418746411432e23d60": {
          value: "",
          feature_name: "Tumble Folding 3rd row Seats",
          feature_type: "2",
          visible: false,
        },
        "63c8f7708746411432e23d26": {
          value: "",
          feature_name: "Vinyl + Fabric Seats",
          feature_type: "2",
          visible: false,
        },
      },
      _id: "645cdc2e70292a422a1c4238",
    },
  ],
  vehicle_type: "SUV",
  video: [],
  car_product: [],
  car_topic: [],
  menu_id: "644f92b38497e0190403d877",
  image_video: "model/video/1689777402077_01_CMS_Jimny_DV_re_1MB.mp4",
  mobile_video: "model/video/1684820685383_01_CMS_JIMNY_MV_1MB.mp4",
  seo_settings: {
    title: "Jimny",
    desc: "jimny",
    keywords: "jimny",
  },
  price1: true,
  price2: false,
  price3: false,
  random_string:
    "3955fb2ac11c225f35ceca2c03e7b91e357d8b6e20200b76f5953fea92ca6969",
  video_delete: "model/video/1689232542088_01_CMS_Jimny_DV_re.mp4",
  variant_propulsion: [],
};

export const bikeRaider = {
  _id: "63b3eba7086fa000e33ca2cb",
  __v: 0,
  brochure: "1672735648844_JimnyBrochure_compressed.pdf",
  comfortable_equipment: [
    {
      title: "Pack your gear for adventure.",
      desc: "Whether navigating the urban jungle or exploring off the beaten track, the Jimny offers ample space and practical storage features to accommodate every need.",
      image_desktop: "1672736273758_section4_desktop.webp",
      image_mobile: "1672736279733_section4_mobile.webp",
      updated_status: 0,
      status: 0,
      _id: "64539da7b4bdd03c0cb21b9a",
    },
    {
      title: "Front seat",
      desc: "<p>The front seat cushion frames are 70mm wider and 55mm taller seatbacks contribute to better shock absorption. </p>",
      image_desktop: "Front_Seat_OP1_1683020755079.jpg",
      image_mobile: "Front_Seat_OP1_1683020766450.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d9d2a36826fc72a060b",
    },
  ],
  configure: [
    {
      title: "Safeguard yourself and others.",
      desc: "Safety is a priority whether trekking through the wilderness or commuting in the city. Thanks to Suzuki's safety technology, you can always drive with peace of mind.\n",
      image_desktop: "1672736182150_section2_desktop.webp",
      image_mobile: "1672736189222_section2_mobile.webp",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "645276932a36826fc728fea3",
    },
    {
      title: "Dual front airbags",
      desc: "<p> As a supplemental restraint system to the seatbelts, the airbags contribute to mitigating the collision force inflicted on the head and chest.</p>",
      image_desktop: "406_OP_1683019704982.jpg",
      image_mobile: "406_OP_1683019712611.jpg",
      updated_keys: ["title", "desc"],
      updated_status: 0,
      status: 0,
      _id: "645234bbb4bdd03c0cb09c43",
    },
    {
      title: "Electronic stability programme®",
      desc: "<p>If the Electronic Stability Programme (ESP®) detects wheel slippage, it automatically adjust the engine torque and brakes to suppress wheel slippage and help the driver stay in directional control.</p>",
      image_desktop: "435_OP_1683019766981.jpg",
      image_mobile: "435_OP_1683019781439.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "645275be2a36826fc728fe53",
    },
  ],
  createdAt: "2023-01-03T09:00:18.467Z",
  degree_view: {
    title: "Stay focused on what matters most.",
    desc: "In the Jimny, there is little to distract you from driving. Its stylish black interior is humble yet handsome, and the controls are designed for quick and easy operation even when wearing gloves.\n",
    highlights:
      "In the Jimny, there’s little to distract you from driving. Its stylish black interior is humble yet handsome, and controls are designed for quick and easy operation even when wearing gloves.\n",
    html: '<iframe src="https://autodev.suzuki.com.ph/suv/all-new-jimny/threesixty/interior" frameborder="0" scrolling="no" style="width:100%; height:400px"></iframe>',
    cta_text: "XEM THÊM",
    cta_link:
      "/pages/unique-sports-style",
    image_desktop: "1676963614148_section1_desktop2.webp",
    image_mobile: "1676963626003_section1_mobile2.webp",
  },
  deleted_images: [],
  description: "<p><br></p>",
  exterior_degree_view: {
    title: "RAIDER R150",
    desc: "Trải nghiệm RAIDER R150 qua nhiều góc độ khác nhau. Đường nét thiết kế góc cạnh, thừa hưởng chất thể thao mạnh mẽ từ những dòng xe đua, cùng với tư thế lái hyper-underbone đặc trưng tạo nên một RAIDER R150 khác biệt.",
    highlights:
      "Kiểu dáng Hyper Underbone độc đáo kết hợp cùng tem màu nổi bật được lấy cảm hứng từ các mẫu xe đua MotoGP huyền thoại của Suzuki, Raider R150 đầy chất thể thao, sẵn sàng là bạn đồng hành cho các tín đồ yêu thích xe côn tay!",
    html: `<iframe style="overflow: hidden; height: 100%;" id="vinsearch" src="https://suzuki-world.com.vn/Raider/index.html" name="myiFrame" marginwidth="0px" marginheight="0px" scrolling="no" allowfullscreen="allowfullscreen" width="100%" height="100%" frameborder="0"></iframe>`,
    cta_text: "XEM THÊM",
    cta_link:
      "/pages/unique-sports-style",
    image_desktop: "1672924249924_section1_desktop1.webp",
    image_mobile: "1672924257863_section1_mobile1.webp",
  },
  images: {
    desk_small: "1672735679375_product-jimny-kineticyellow.webp",
    desk_medium: "1672735686367_product-jimny-kineticyellow.webp",
    desk_large: "1672735707003_home-1920x738_3.webp",
    desk_xl: "1672735716858_home-1920x738_3.webp",
    desk_banners: ["1672735747875_banner_desktop.webp"],
    mob_small: "1672735804601_product-jimny.webp",
    mob_medium: "1672735810194_product-jimny.webp",
    mob_large: "1672735819303_home-768x564-mob_3.webp",
    mob_xl: "1672735773120_banner_mobile.webp",
    mob_banners: ["jimny.webp_1679987529864"],
  },
  key_features: [],
  mileage: "NAA",
  model_code: "JI",
  model_name: "VSTROM250",
  page_url: "jimny",
  performance: [
    {
      title: "Power your off-road ambitions.",
      desc: "Nothing stands in your way when driving the Jimny. It takes you wherever you want to go with unrivalled agility, precision control and powerful torque when you need it most.",
      image_desktop: "1672736235450_section3_desktop.webp",
      image_mobile: "1672736241716_section3_mobille.webp",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "645276a52a36826fc728fef3",
    },
    {
      title: "4AT (GL/GLX)",
      desc: "<p>The 4-speed automatic transmission has been renewed to match the K15B engine, with refined transmission components that reduces friction and enhanced fuel efficiency.</p>",
      image_desktop: "118_1683019875469.jpg",
      image_mobile: "118_1683019858704.jpg",
      updated_status: 0,
      status: 0,
      _id: "64539d40b4bdd03c0cb21ab8",
    },
    {
      title: "5MT (GL) ",
      desc: "<p>Based on the current model’s 5 speed manual transmission, the new Jimny’s transmission system has optimized gear ratios and better fuel efficiency.</p>",
      image_desktop: "119_1683019890028.jpg",
      image_mobile: "119_1683019899471.jpg",
      updated_keys: ["title", "desc"],
      updated_status: 0,
      status: 0,
      _id: "645235dab4bdd03c0cb09cf9",
    },
    {
      title: "4WD with low range transfer gear ",
      desc: "<p>Part-time 4WD allows switching between 4WD, for optimal off-road performance, and 2WD for better fuel economy and enhanced cornering on paved roads. 4WD low range transfer gear provides extra torque in the 4L mode for rough terrain.</p>",
      image_desktop: "120_OP_1683019987338.jpg",
      image_mobile: "120_OP_1683019963703.jpg",
      updated_status: 0,
      status: 0,
      _id: "64539d55b4bdd03c0cb21ae0",
    },
    {
      title: "Ladder frame ",
      desc: "<p>Compared to a monocoque body, it is more resistant to torsion and better suited for off-road use. With the ladder frame, the driver can drive confidently even on rough terrain.</p>",
      image_desktop: "408_1683020120950.jpg",
      image_mobile: "408_1683020113567.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d60b4bdd03c0cb21b08",
    },
    {
      title: "3-link rigid axle suspension with coil spring",
      desc: "<p>The new Jimny is equipped with long-stroke 3-link suspension that is teamed with rigid full wheel axles on both front and rear to support strong traction on uneven terrain. It’s a far more robust solution than the independent suspensions found on many of today’s SUVs.</p>",
      image_desktop: "412_OP_1683020142977.jpg",
      image_mobile: "412_OP_1683020152999.jpg",
      updated_status: 0,
      status: 0,
      _id: "64539d6ab4bdd03c0cb21b30",
    },
    {
      title: "Three angles",
      desc: "<p>The Jimny can climb over obstacles and steep hills without scrapping its bumpers or underbody, thanks to an ample approach angle (37 deg.), and ramp breakover angle (28 deg.) and departure angle (49 deg.).</p>",
      image_desktop: "414_1_1683020172906.jpg",
      image_mobile: "414_1_1683020164471.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d762a36826fc72a05a6",
    },
    {
      title: "Hill hold control",
      desc: "<p>Move upward and onward when starting on steep slopes. Even on bumpy slopes that vary on the right and left sides, this feature helps prevent rearward rolling so you can focus on controlling acceleration.</p>",
      image_desktop: "418_OP_1683020193775.jpg",
      image_mobile: "418_OP_1683020202994.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d812a36826fc72a05ce",
    },
    {
      title: "Hill descent control",
      desc: "<p>On tricky descents, with a push of a button, the system is designed to automatically apply the brakes and maintain a fixed speed so you can concentrate on steering without using the brake pedal or clutch.</p>",
      image_desktop: "419_OP_1683020281957.jpg",
      image_mobile: "419_OP_1683020270725.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d8bb4bdd03c0cb21b6c",
    },
  ],
  published: 1,
  published_value: 3,
  retail_price: "NA",
  see_details: {
    title: "4WD with low range transfer gear.",
    desc: "At your command, the Jimny adapts to every situation. Let ALLGRIP PRO satisfy your adventurous mind with its on and off-road mastery.\n",
    cta_text: "KNOW MORE",
    cta_link:
      "https://website.suzukibusinesscloud-philippinesuat.com/pages/Jimny-detail",
    image_desktop: "1675416260666_JimnySECTION5(1).jpg",
    image_mobile: "1672736339675_section5_mobile.webp",
  },
  short_description:
    "READY TO CONQUER THE WILD\nBuilt to tackle the harshest\nweather and terrain, the Jimny goes where other vehicles fear to tread. Take on\nnature’s challenges. Unleash your spirit of adventure and get ready to explore..",
  sliders: [],
  status: 0,
  updatedAt: "2023-07-19T14:37:43.687Z",
  updated_images: [
    {
      Key: "Desk_1922X1153Exterior_1683986594465.jpg",
    },
    {
      Key: "Desk765x1920Interior_1683986597357.jpg",
    },
    {
      Key: "fileuploader/Maskgroup_1689579166831.png",
    },
  ],
  updated_status: {
    basic_details: 0,
    images: 0,
    variants: 0,
    features: 0,
    sliders: 0,
    degree_view: 0,
    degree_view_images: 0,
    configure: 0,
    performance: 0,
    see_details: 0,
    key_features: 0,
    comfortable_equipment: 0,
    exterior_degree_view: 0,
    video: 0,
    car_product: 0,
    car_topic: 0,
    menu_list: 0,
    seo_settings: 0,
  },
  variants: [
    {
      variant_name: "RAIDER R150",
      variant_code: "JI08",
      fuel_type: "GAS",
      engine_cc: "1500",
      transmission_type: "Manual",
      emission: "Euro 4",
      power: "",
      co2WltpMin: "",
      co2WltpComb: "",
      fuelConsumptionComb: "",
      fuelConsumptionMax: "",
      fuelConsumptionMin: "",
      smcVariantCode: "",
      drive: "",
      grade: "",
      description2: "",
      year: "",
      enquiry: "true",
      gearbox: "",
      propulsion: "",
      status: 2,
      colors: [
        {
          color_name: "ĐỎ - ĐEN",
          color_code: "Z2S",
          hex_code: "#F82828",
          hex_code2 : "#0C0C0C",
          image: `${configuration.IMG_STATIC_URL}assets/images/raider/Red_Black.png`,
          on_road_price: "51.190.000 VND",
          status: 0,
          is_default: false,
          _id: "645df60d372e859e942ea56f",
        },
        {
          color_name: "XANH - ĐEN",
          color_code: "ZJ3",
          hex_code: "#CDE3F6",
          hex_code2 : "#B8B8B8",
          image: `${configuration.IMG_STATIC_URL}assets/images/raider/Blue_Black.png`,
          on_road_price: "45.990.000 VND",
          status: 0,
          is_default: false,
          _id: "645df6b2372e859e942eabf3",
        },
        {
          color_name: "XANH - ĐEN",
          color_code: "ZJ3",
          hex_code: "#2B4071",
          hex_code2 : "#121115",
          image: `${configuration.IMG_STATIC_URL}assets/images/raider/raider_DarkBlue.png`,
          on_road_price: "51.190.000 VND",
          status: 0,
          is_default: false,
          _id: "645df6b2372e859e942eabf3",
        },
        {
          color_name: "NÂU - ĐEN",
          color_code: "ZJ3",
          hex_code: "#C6B0B4",
          hex_code2 : "#7F7F7F",
          image: `${configuration.IMG_STATIC_URL}assets/images/raider/Brown_black.png`,
          on_road_price: "50.990.000 VND",
          status: 0,
          is_default: false,
          _id: "645df6b2372e859e942eabf3",
        },
        {
          color_name: "TRẮNG – ĐỎ - ĐEN",
          color_code: "ZJ3",
          hex_code: "#E08C90",
          hex_code2 : "#9C9C9C",
          image: `${configuration.IMG_STATIC_URL}assets/images/raider/White_Red_Black.png`,
          on_road_price: "50.990.000 VND",
          status: 0,
          is_default: false,
          _id: "645df6b2372e859e942eabf3",
        },
        {
          color_name: "XÁM – ĐEN",
          color_code: "26U",
          hex_code: "#A0A0A4",
          hex_code2 : " #7F7F7F",
          image: `${configuration.IMG_STATIC_URL}assets/images/raider/Gray_Black.png`,
          on_road_price: "51.190.000 VND",
          status: 0,
          is_default: false,
          _id: "645df4d970292a422a1d32ba",
        },
        /* {
          color_name: "XANH – ĐEN",
          color_code: "ZJ3",
          hex_code: "#2B4071",
          hex_code2 : "#121115",
          image: "/assets/images/raider/RaiderThird.png",
          on_road_price: "51.190.000 VND",
          status: 0,
          is_default: false,
          _id: "645df6b2372e859e942eabf3",
        }, */
      ],
      features: {
        "63d77284cba94805f0b09932": {
          value: "1462",
          feature_name: "Chiều dài x rộng x cao",
          feature_type: "1",
          visible: true,
        },
        "63d773cecba94805f0b09934": {
          value: " 130 / 4,000",
          feature_name: "Maximum Torque (Nm/RPM)",
          feature_type: "1",
          visible: true,
        },
        "63d77348cba94805f0b09933": {
          value: " 100 / 6,000",
          feature_name: "Maximum output (HP/RPM)",
          feature_type: "1",
          visible: true,
        },
        "63d77525cba94805f0b09937": {
          value: "210",
          feature_name: "Minimum Ground Clearance (mm)",
          feature_type: "1",
          visible: true,
        },
        "63d77498cba94805f0b09935": {
          value: "4.9",
          feature_name: "Minimum Turning Radius (m)",
          feature_type: "1",
          visible: true,
        },
        "63d775dbcba94805f0b09939": {
          value: " 4 Person",
          feature_name: "Seating Capacity",
          feature_type: "1",
          visible: true,
        },
        "63d76ffbcba94805f0b09931": {
          value: "5-Speed Manual",
          feature_name: "Transmission Type",
          feature_type: "1",
          visible: true,
        },
        "63d77585cba94805f0b09938": {
          value: " 195/80R15",
          feature_name: "Tyres",
          feature_type: "1",
          visible: true,
        },
        "63c8f90f8746411432e23d42": {
          value: "",
          feature_name: "12V Accessory Sockets",
          feature_type: "2",
          visible: false,
        },
        "63c8f8908746411432e23d38": {
          value: "",
          feature_name: "22 Rope Hooks",
          feature_type: "2",
          visible: false,
        },
        "63c8f9e28746411432e23d58": {
          value: "",
          feature_name: "50/50 Split Full Swing Hinges Back Door with Camlock",
          feature_type: "2",
          visible: false,
        },
        "63c8fb8a8746411432e23d7a": {
          value: "",
          feature_name: "ABS",
          feature_type: "2",
          visible: false,
        },
        "63c8f7328746411432e23d20": {
          value: "",
          feature_name: "ABS with EBD",
          feature_type: "2",
          visible: false,
        },
        "63c8f8c38746411432e23d3e": {
          value: "",
          feature_name: "Air Conditioner",
          feature_type: "2",
          visible: false,
        },
        "63c8fb438746411432e23d76": {
          value: "",
          feature_name: "Air Conditioning",
          feature_type: "2",
          visible: false,
        },
        "63c8fab08746411432e23d6c": {
          value: "",
          feature_name: "Air Conditioning with Manual Controls",
          feature_type: "2",
          visible: false,
        },
        "63c8fb978746411432e23d7c": {
          value: "",
          feature_name: "Airbags",
          feature_type: "2",
          visible: false,
        },
        "63c8f9bf8746411432e23d54": {
          value: "",
          feature_name: "Aluminium Rivetless Exterior Panel",
          feature_type: "2",
          visible: false,
        },
        "63c8f8528746411432e23d34": {
          value: "",
          feature_name: "Angle Post",
          feature_type: "2",
          visible: false,
        },
        "63c8f7a18746411432e23d28": {
          value: "",
          feature_name: "Anti-Brake Lock System (ABS)",
          feature_type: "2",
          visible: false,
        },
        "63c8fa508746411432e23d62": {
          value: "",
          feature_name: "Assist Grip",
          feature_type: "2",
          visible: false,
        },
        "63c8f8b18746411432e23d3c": {
          value: "",
          feature_name: "Audio System",
          feature_type: "2",
          visible: false,
        },
        "63c8fb248746411432e23d72": {
          value: '9" Touchscreen w/o Clinometer',
          feature_name: "Audio Unit",
          feature_type: "2",
          visible: true,
        },
        "63c8f8018746411432e23d2e": {
          value: "            -",
          feature_name: "Automatic Lamps",
          feature_type: "2",
          visible: true,
        },
        "63c8f7478746411432e23d22": {
          value: "",
          feature_name: "Break Assist Function",
          feature_type: "2",
          visible: false,
        },
        "63c8f9898746411432e23d4e": {
          value: "",
          feature_name: "Ceiling Grab Handles",
          feature_type: "2",
          visible: false,
        },
        "63c8f6e28746411432e23d1c": {
          value: "",
          feature_name: "Central Door locks",
          feature_type: "2",
          visible: false,
        },
        "63c8faf38746411432e23d6e": {
          value: "",
          feature_name: "Cruise Control",
          feature_type: "2",
          visible: false,
        },
        "63c8f8fd8746411432e23d40": {
          value: "",
          feature_name: "Cup holder",
          feature_type: "2",
          visible: false,
        },
        "63c8fa698746411432e23d66": {
          value: "",
          feature_name: "Defogger",
          feature_type: "2",
          visible: false,
        },
        "63c8fa788746411432e23d68": {
          value: "",
          feature_name: "Door locks",
          feature_type: "2",
          visible: false,
        },
        "63c8f71b8746411432e23d1e": {
          value: "Y",
          feature_name: "Dual Airbags",
          feature_type: "2",
          visible: true,
        },
        "63c8f8a28746411432e23d3a": {
          value: "",
          feature_name: "Electric Power Steering",
          feature_type: "2",
          visible: false,
        },
        "63c8fba98746411432e23d7e": {
          value: "Y",
          feature_name: "Electronic Stability Program (ESP)",
          feature_type: "2",
          visible: true,
        },
        "63c8f83a8746411432e23d32": {
          value: "",
          feature_name: "Engine Auto Start Stop (EASS)",
          feature_type: "2",
          visible: false,
        },
        "63c8fb7c8746411432e23d78": {
          value: "Y",
          feature_name: "Fabric Seats",
          feature_type: "2",
          visible: true,
        },
        "63c8fa128746411432e23d5a": {
          value: "",
          feature_name: "Front and Side Clearance Lamp",
          feature_type: "2",
          visible: false,
        },
        "63c8f8698746411432e23d36": {
          value: "",
          feature_name: "Gate Type",
          feature_type: "2",
          visible: false,
        },
        "63c8f75a8746411432e23d24": {
          value: "",
          feature_name: "Halogen Multi Reflector Headlamps",
          feature_type: "2",
          visible: false,
        },
        "63c8f7ea8746411432e23d2c": {
          value: "Halogen Multi Reflector",
          feature_name: "Headlamps",
          feature_type: "2",
          visible: true,
        },
        "63c8f96a8746411432e23d4c": {
          value: "",
          feature_name: "Heavy duty door locks",
          feature_type: "2",
          visible: false,
        },
        "63c8f8148746411432e23d30": {
          value: "Y",
          feature_name: "Hill Descent Control",
          feature_type: "2",
          visible: true,
        },
        "63c8fbc98746411432e23d82": {
          value: "Y",
          feature_name: "Hill Hold Control",
          feature_type: "2",
          visible: true,
        },
        "63c8f92d8746411432e23d46": {
          value: "",
          feature_name: "Immobilizer",
          feature_type: "2",
          visible: false,
        },
        "63c8f6068746411432e23d12": {
          value: "",
          feature_name: "LED Headlamps",
          feature_type: "2",
          visible: false,
        },
        "63c8f99b8746411432e23d50": {
          value: "",
          feature_name: "Ladder Rack",
          feature_type: "2",
          visible: false,
        },
        "63c8f6718746411432e23d16": {
          value: "",
          feature_name: "Letherette + Fabric Seats",
          feature_type: "2",
          visible: false,
        },
        "63c8f9d08746411432e23d56": {
          value: "",
          feature_name: "Marine Plywood inner panel",
          feature_type: "2",
          visible: false,
        },
        "63c8f6828746411432e23d18": {
          value: "",
          feature_name: "Multi Information Display",
          feature_type: "2",
          visible: false,
        },
        "63c8fb348746411432e23d74": {
          value: "",
          feature_name: "Power Steering",
          feature_type: "2",
          visible: false,
        },
        "63c8f69f8746411432e23d1a": {
          value: "",
          feature_name: "Power Window",
          feature_type: "2",
          visible: false,
        },
        "63c8fbba8746411432e23d80": {
          value: "",
          feature_name: "Power Windows",
          feature_type: "2",
          visible: false,
        },
        "63c8fa238746411432e23d5c": {
          value: "",
          feature_name: "Rear Red Reflector",
          feature_type: "2",
          visible: false,
        },
        "63c8fb118746411432e23d70": {
          value: "",
          feature_name: "Reverse Camera",
          feature_type: "2",
          visible: false,
        },
        "63c8fa5c8746411432e23d64": {
          value: "",
          feature_name: "Reverse Sensor",
          feature_type: "2",
          visible: false,
        },
        "63c8f6518746411432e23d14": {
          value: "",
          feature_name: "Roof Rails",
          feature_type: "2",
          visible: false,
        },
        "63c8f93a8746411432e23d48": {
          value: "",
          feature_name: "Seats",
          feature_type: "2",
          visible: false,
        },
        "63c8fa888746411432e23d6a": {
          value: "",
          feature_name: "Side Mirrors",
          feature_type: "2",
          visible: false,
        },
        "63c8f94b8746411432e23d4a": {
          value: "",
          feature_name: "Sliding Glass Window",
          feature_type: "2",
          visible: false,
        },
        "63c8fa318746411432e23d5e": {
          value: "",
          feature_name: "Speaker",
          feature_type: "2",
          visible: false,
        },
        "63c8f7cd8746411432e23d2a": {
          value: "",
          feature_name: "Special Edition Standard Accessories",
          feature_type: "2",
          visible: false,
        },
        "63c8f91e8746411432e23d44": {
          value: "",
          feature_name: "Steering Lock",
          feature_type: "2",
          visible: false,
        },
        "63c8f9af8746411432e23d52": {
          value: "",
          feature_name: "Tool Box",
          feature_type: "2",
          visible: false,
        },
        "63c8fa418746411432e23d60": {
          value: "",
          feature_name: "Tumble Folding 3rd row Seats",
          feature_type: "2",
          visible: false,
        },
        "63c8f7708746411432e23d26": {
          value: "",
          feature_name: "Vinyl + Fabric Seats",
          feature_type: "2",
          visible: false,
        },
      },
      _id: "645cdc2e70292a422a1c4238",
    },
  ],
  vehicle_type: "SUV",
  video: [],
  car_product: [],
  car_topic: [],
  menu_id: "644f92b38497e0190403d877",
  image_video: "model/video/1689777402077_01_CMS_Jimny_DV_re_1MB.mp4",
  mobile_video: "model/video/1684820685383_01_CMS_JIMNY_MV_1MB.mp4",
  seo_settings: {
    title: "Jimny",
    desc: "jimny",
    keywords: "jimny",
  },
  price1: true,
  price2: false,
  price3: false,
  random_string:
    "3955fb2ac11c225f35ceca2c03e7b91e357d8b6e20200b76f5953fea92ca6969",
  video_delete: "model/video/1689232542088_01_CMS_Jimny_DV_re.mp4",
  variant_propulsion: [],
};

export const bikeSatria = {
  _id: "63b3eba7086fa000e33ca2cb",
  __v: 0,
  brochure: "1672735648844_JimnyBrochure_compressed.pdf",
  comfortable_equipment: [
    {
      title: "Pack your gear for adventure.",
      desc: "Whether navigating the urban jungle or exploring off the beaten track, the Jimny offers ample space and practical storage features to accommodate every need.",
      image_desktop: "1672736273758_section4_desktop.webp",
      image_mobile: "1672736279733_section4_mobile.webp",
      updated_status: 0,
      status: 0,
      _id: "64539da7b4bdd03c0cb21b9a",
    },
    {
      title: "Front seat",
      desc: "<p>The front seat cushion frames are 70mm wider and 55mm taller seatbacks contribute to better shock absorption. </p>",
      image_desktop: "Front_Seat_OP1_1683020755079.jpg",
      image_mobile: "Front_Seat_OP1_1683020766450.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d9d2a36826fc72a060b",
    },
  ],
  configure: [
    {
      title: "Safeguard yourself and others.",
      desc: "Safety is a priority whether trekking through the wilderness or commuting in the city. Thanks to Suzuki's safety technology, you can always drive with peace of mind.\n",
      image_desktop: "1672736182150_section2_desktop.webp",
      image_mobile: "1672736189222_section2_mobile.webp",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "645276932a36826fc728fea3",
    },
    {
      title: "Dual front airbags",
      desc: "<p> As a supplemental restraint system to the seatbelts, the airbags contribute to mitigating the collision force inflicted on the head and chest.</p>",
      image_desktop: "406_OP_1683019704982.jpg",
      image_mobile: "406_OP_1683019712611.jpg",
      updated_keys: ["title", "desc"],
      updated_status: 0,
      status: 0,
      _id: "645234bbb4bdd03c0cb09c43",
    },
    {
      title: "Electronic stability programme®",
      desc: "<p>If the Electronic Stability Programme (ESP®) detects wheel slippage, it automatically adjust the engine torque and brakes to suppress wheel slippage and help the driver stay in directional control.</p>",
      image_desktop: "435_OP_1683019766981.jpg",
      image_mobile: "435_OP_1683019781439.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "645275be2a36826fc728fe53",
    },
  ],
  createdAt: "2023-01-03T09:00:18.467Z",
  degree_view: {
    title: "Stay focused on what matters most.",
    desc: "In the Jimny, there is little to distract you from driving. Its stylish black interior is humble yet handsome, and the controls are designed for quick and easy operation even when wearing gloves.\n",
    highlights:
      "In the Jimny, there’s little to distract you from driving. Its stylish black interior is humble yet handsome, and controls are designed for quick and easy operation even when wearing gloves.\n",
    html: '<iframe src="https://autodev.suzuki.com.ph/suv/all-new-jimny/threesixty/interior" frameborder="0" scrolling="no" style="width:100%; height:400px"></iframe>',
    cta_text: "XEM THÊM",
    cta_link:
      "/pages/real-sport-experience",
    image_desktop: "1676963614148_section1_desktop2.webp",
    image_mobile: "1676963626003_section1_mobile2.webp",
  },
  deleted_images: [],
  description: "<p><br></p>",
  exterior_degree_view: {
    title: "SATRIA F150",
    desc: "Trải nghiệm SATRIA F150 qua nhiều góc độ khác nhau. Được nhập khẩu chính hãng từ Indonesia với chất lượng đảm bảo cùng thiết kế mạnh mẽ, góc cạnh, mang dáng vóc thể thao của dòng hyper-underbone đặc trưng.",
    highlights:
      "Thiết kế thể thao cùng động cơ khỏe mạnh, Satria F150 sẵn sàng cùng bạn chinh phục mọi thử thách. Mang đến cho bạn trải nghiệm tốc độ khác biệt.",
    html: `<iframe style="overflow: hidden; height: 100%;" id="vinsearch" src="https://suzuki-world.com.vn/SaTria/index.html" name="myiFrame" marginwidth="0px" marginheight="0px" scrolling="no" allowfullscreen="allowfullscreen" width="100%" height="100%" frameborder="0"></iframe>`,
    cta_text: "XEM THÊM",
    cta_link:
      "/pages/real-sport-experience",
    image_desktop: "1672924249924_section1_desktop1.webp",
    image_mobile: "1672924257863_section1_mobile1.webp",
  },
  images: {
    desk_small: "1672735679375_product-jimny-kineticyellow.webp",
    desk_medium: "1672735686367_product-jimny-kineticyellow.webp",
    desk_large: "1672735707003_home-1920x738_3.webp",
    desk_xl: "1672735716858_home-1920x738_3.webp",
    desk_banners: ["1672735747875_banner_desktop.webp"],
    mob_small: "1672735804601_product-jimny.webp",
    mob_medium: "1672735810194_product-jimny.webp",
    mob_large: "1672735819303_home-768x564-mob_3.webp",
    mob_xl: "1672735773120_banner_mobile.webp",
    mob_banners: ["jimny.webp_1679987529864"],
  },
  key_features: [],
  mileage: "NAA",
  model_code: "JI",
  model_name: "SATRIA F150",
  page_url: "jimny",
  performance: [
    {
      title: "Power your off-road ambitions.",
      desc: "Nothing stands in your way when driving the Jimny. It takes you wherever you want to go with unrivalled agility, precision control and powerful torque when you need it most.",
      image_desktop: "1672736235450_section3_desktop.webp",
      image_mobile: "1672736241716_section3_mobille.webp",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "645276a52a36826fc728fef3",
    },
    {
      title: "4AT (GL/GLX)",
      desc: "<p>The 4-speed automatic transmission has been renewed to match the K15B engine, with refined transmission components that reduces friction and enhanced fuel efficiency.</p>",
      image_desktop: "118_1683019875469.jpg",
      image_mobile: "118_1683019858704.jpg",
      updated_status: 0,
      status: 0,
      _id: "64539d40b4bdd03c0cb21ab8",
    },
    {
      title: "5MT (GL) ",
      desc: "<p>Based on the current model’s 5 speed manual transmission, the new Jimny’s transmission system has optimized gear ratios and better fuel efficiency.</p>",
      image_desktop: "119_1683019890028.jpg",
      image_mobile: "119_1683019899471.jpg",
      updated_keys: ["title", "desc"],
      updated_status: 0,
      status: 0,
      _id: "645235dab4bdd03c0cb09cf9",
    },
    {
      title: "4WD with low range transfer gear ",
      desc: "<p>Part-time 4WD allows switching between 4WD, for optimal off-road performance, and 2WD for better fuel economy and enhanced cornering on paved roads. 4WD low range transfer gear provides extra torque in the 4L mode for rough terrain.</p>",
      image_desktop: "120_OP_1683019987338.jpg",
      image_mobile: "120_OP_1683019963703.jpg",
      updated_status: 0,
      status: 0,
      _id: "64539d55b4bdd03c0cb21ae0",
    },
    {
      title: "Ladder frame ",
      desc: "<p>Compared to a monocoque body, it is more resistant to torsion and better suited for off-road use. With the ladder frame, the driver can drive confidently even on rough terrain.</p>",
      image_desktop: "408_1683020120950.jpg",
      image_mobile: "408_1683020113567.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d60b4bdd03c0cb21b08",
    },
    {
      title: "3-link rigid axle suspension with coil spring",
      desc: "<p>The new Jimny is equipped with long-stroke 3-link suspension that is teamed with rigid full wheel axles on both front and rear to support strong traction on uneven terrain. It’s a far more robust solution than the independent suspensions found on many of today’s SUVs.</p>",
      image_desktop: "412_OP_1683020142977.jpg",
      image_mobile: "412_OP_1683020152999.jpg",
      updated_status: 0,
      status: 0,
      _id: "64539d6ab4bdd03c0cb21b30",
    },
    {
      title: "Three angles",
      desc: "<p>The Jimny can climb over obstacles and steep hills without scrapping its bumpers or underbody, thanks to an ample approach angle (37 deg.), and ramp breakover angle (28 deg.) and departure angle (49 deg.).</p>",
      image_desktop: "414_1_1683020172906.jpg",
      image_mobile: "414_1_1683020164471.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d762a36826fc72a05a6",
    },
    {
      title: "Hill hold control",
      desc: "<p>Move upward and onward when starting on steep slopes. Even on bumpy slopes that vary on the right and left sides, this feature helps prevent rearward rolling so you can focus on controlling acceleration.</p>",
      image_desktop: "418_OP_1683020193775.jpg",
      image_mobile: "418_OP_1683020202994.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d812a36826fc72a05ce",
    },
    {
      title: "Hill descent control",
      desc: "<p>On tricky descents, with a push of a button, the system is designed to automatically apply the brakes and maintain a fixed speed so you can concentrate on steering without using the brake pedal or clutch.</p>",
      image_desktop: "419_OP_1683020281957.jpg",
      image_mobile: "419_OP_1683020270725.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d8bb4bdd03c0cb21b6c",
    },
  ],
  published: 1,
  published_value: 3,
  retail_price: "NA",
  see_details: {
    title: "4WD with low range transfer gear.",
    desc: "At your command, the Jimny adapts to every situation. Let ALLGRIP PRO satisfy your adventurous mind with its on and off-road mastery.\n",
    cta_text: "KNOW MORE",
    cta_link:
      "https://website.suzukibusinesscloud-philippinesuat.com/pages/Jimny-detail",
    image_desktop: "1675416260666_JimnySECTION5(1).jpg",
    image_mobile: "1672736339675_section5_mobile.webp",
  },
  short_description:
    "READY TO CONQUER THE WILD\nBuilt to tackle the harshest\nweather and terrain, the Jimny goes where other vehicles fear to tread. Take on\nnature’s challenges. Unleash your spirit of adventure and get ready to explore..",
  sliders: [],
  status: 0,
  updatedAt: "2023-07-19T14:37:43.687Z",
  updated_images: [
    {
      Key: "Desk_1922X1153Exterior_1683986594465.jpg",
    },
    {
      Key: "Desk765x1920Interior_1683986597357.jpg",
    },
    {
      Key: "fileuploader/Maskgroup_1689579166831.png",
    },
  ],
  updated_status: {
    basic_details: 0,
    images: 0,
    variants: 0,
    features: 0,
    sliders: 0,
    degree_view: 0,
    degree_view_images: 0,
    configure: 0,
    performance: 0,
    see_details: 0,
    key_features: 0,
    comfortable_equipment: 0,
    exterior_degree_view: 0,
    video: 0,
    car_product: 0,
    car_topic: 0,
    menu_list: 0,
    seo_settings: 0,
  },
  variants: [
    {
      variant_name: "SATRIA F150",
      variant_code: "JI08",
      fuel_type: "GAS",
      engine_cc: "1500",
      transmission_type: "Manual",
      emission: "Euro 4",
      power: "",
      co2WltpMin: "",
      co2WltpComb: "",
      fuelConsumptionComb: "",
      fuelConsumptionMax: "",
      fuelConsumptionMin: "",
      smcVariantCode: "",
      drive: "",
      grade: "",
      description2: "",
      year: "",
      enquiry: "true",
      gearbox: "",
      propulsion: "",
      status: 2,
      colors: [
        {
          color_name: "XANH-BẠC-ĐEN",
          color_code: "Z2S",
          hex_code: "#D7E0F9",
          hex_code2: " #9C9C9C",
          image: `${configuration.IMG_STATIC_URL}assets/images/satria/Blue_Silver_Black.png`,
          on_road_price: "48.490.000 VND",
          status: 0,
          is_default: false,
          _id: "645df60d372e859e942ea56f",
        },
        {
          color_name: "ĐEN-TRẮNG",
          color_code: "26U",
          hex_code: "#26252A",
          hex_code2: "#F6F5F4",
          image: `${configuration.IMG_STATIC_URL}assets/images/satria/Black_white.png`,
          on_road_price: "53.490.000 VND",
          status: 0,
          is_default: false,
          _id: "645df4d970292a422a1d32ba",
        },
        {
          color_name: "ĐEN-ĐỎ",
          color_code: "ZJ3",
          hex_code: "#A2A2A2",
          hex_code2: "#E49EA1",
          image: `${configuration.IMG_STATIC_URL}assets/images/satria/Black_Red.png`,
          on_road_price: "53.490.000 VND",
          status: 0,
          is_default: false,
          _id: "645df6b2372e859e942eabf3",
        },
      ],
      features: {
        "63d77284cba94805f0b09932": {
          value: "1462",
          feature_name: "Chiều dài x rộng x cao",
          feature_type: "1",
          visible: true,
        },
        "63d773cecba94805f0b09934": {
          value: " 130 / 4,000",
          feature_name: "Maximum Torque (Nm/RPM)",
          feature_type: "1",
          visible: true,
        },
        "63d77348cba94805f0b09933": {
          value: " 100 / 6,000",
          feature_name: "Maximum output (HP/RPM)",
          feature_type: "1",
          visible: true,
        },
        "63d77525cba94805f0b09937": {
          value: "210",
          feature_name: "Minimum Ground Clearance (mm)",
          feature_type: "1",
          visible: true,
        },
        "63d77498cba94805f0b09935": {
          value: "4.9",
          feature_name: "Minimum Turning Radius (m)",
          feature_type: "1",
          visible: true,
        },
        "63d775dbcba94805f0b09939": {
          value: " 4 Person",
          feature_name: "Seating Capacity",
          feature_type: "1",
          visible: true,
        },
        "63d76ffbcba94805f0b09931": {
          value: "5-Speed Manual",
          feature_name: "Transmission Type",
          feature_type: "1",
          visible: true,
        },
        "63d77585cba94805f0b09938": {
          value: " 195/80R15",
          feature_name: "Tyres",
          feature_type: "1",
          visible: true,
        },
        "63c8f90f8746411432e23d42": {
          value: "",
          feature_name: "12V Accessory Sockets",
          feature_type: "2",
          visible: false,
        },
        "63c8f8908746411432e23d38": {
          value: "",
          feature_name: "22 Rope Hooks",
          feature_type: "2",
          visible: false,
        },
        "63c8f9e28746411432e23d58": {
          value: "",
          feature_name: "50/50 Split Full Swing Hinges Back Door with Camlock",
          feature_type: "2",
          visible: false,
        },
        "63c8fb8a8746411432e23d7a": {
          value: "",
          feature_name: "ABS",
          feature_type: "2",
          visible: false,
        },
        "63c8f7328746411432e23d20": {
          value: "",
          feature_name: "ABS with EBD",
          feature_type: "2",
          visible: false,
        },
        "63c8f8c38746411432e23d3e": {
          value: "",
          feature_name: "Air Conditioner",
          feature_type: "2",
          visible: false,
        },
        "63c8fb438746411432e23d76": {
          value: "",
          feature_name: "Air Conditioning",
          feature_type: "2",
          visible: false,
        },
        "63c8fab08746411432e23d6c": {
          value: "",
          feature_name: "Air Conditioning with Manual Controls",
          feature_type: "2",
          visible: false,
        },
        "63c8fb978746411432e23d7c": {
          value: "",
          feature_name: "Airbags",
          feature_type: "2",
          visible: false,
        },
        "63c8f9bf8746411432e23d54": {
          value: "",
          feature_name: "Aluminium Rivetless Exterior Panel",
          feature_type: "2",
          visible: false,
        },
        "63c8f8528746411432e23d34": {
          value: "",
          feature_name: "Angle Post",
          feature_type: "2",
          visible: false,
        },
        "63c8f7a18746411432e23d28": {
          value: "",
          feature_name: "Anti-Brake Lock System (ABS)",
          feature_type: "2",
          visible: false,
        },
        "63c8fa508746411432e23d62": {
          value: "",
          feature_name: "Assist Grip",
          feature_type: "2",
          visible: false,
        },
        "63c8f8b18746411432e23d3c": {
          value: "",
          feature_name: "Audio System",
          feature_type: "2",
          visible: false,
        },
        "63c8fb248746411432e23d72": {
          value: '9" Touchscreen w/o Clinometer',
          feature_name: "Audio Unit",
          feature_type: "2",
          visible: true,
        },
        "63c8f8018746411432e23d2e": {
          value: "            -",
          feature_name: "Automatic Lamps",
          feature_type: "2",
          visible: true,
        },
        "63c8f7478746411432e23d22": {
          value: "",
          feature_name: "Break Assist Function",
          feature_type: "2",
          visible: false,
        },
        "63c8f9898746411432e23d4e": {
          value: "",
          feature_name: "Ceiling Grab Handles",
          feature_type: "2",
          visible: false,
        },
        "63c8f6e28746411432e23d1c": {
          value: "",
          feature_name: "Central Door locks",
          feature_type: "2",
          visible: false,
        },
        "63c8faf38746411432e23d6e": {
          value: "",
          feature_name: "Cruise Control",
          feature_type: "2",
          visible: false,
        },
        "63c8f8fd8746411432e23d40": {
          value: "",
          feature_name: "Cup holder",
          feature_type: "2",
          visible: false,
        },
        "63c8fa698746411432e23d66": {
          value: "",
          feature_name: "Defogger",
          feature_type: "2",
          visible: false,
        },
        "63c8fa788746411432e23d68": {
          value: "",
          feature_name: "Door locks",
          feature_type: "2",
          visible: false,
        },
        "63c8f71b8746411432e23d1e": {
          value: "Y",
          feature_name: "Dual Airbags",
          feature_type: "2",
          visible: true,
        },
        "63c8f8a28746411432e23d3a": {
          value: "",
          feature_name: "Electric Power Steering",
          feature_type: "2",
          visible: false,
        },
        "63c8fba98746411432e23d7e": {
          value: "Y",
          feature_name: "Electronic Stability Program (ESP)",
          feature_type: "2",
          visible: true,
        },
        "63c8f83a8746411432e23d32": {
          value: "",
          feature_name: "Engine Auto Start Stop (EASS)",
          feature_type: "2",
          visible: false,
        },
        "63c8fb7c8746411432e23d78": {
          value: "Y",
          feature_name: "Fabric Seats",
          feature_type: "2",
          visible: true,
        },
        "63c8fa128746411432e23d5a": {
          value: "",
          feature_name: "Front and Side Clearance Lamp",
          feature_type: "2",
          visible: false,
        },
        "63c8f8698746411432e23d36": {
          value: "",
          feature_name: "Gate Type",
          feature_type: "2",
          visible: false,
        },
        "63c8f75a8746411432e23d24": {
          value: "",
          feature_name: "Halogen Multi Reflector Headlamps",
          feature_type: "2",
          visible: false,
        },
        "63c8f7ea8746411432e23d2c": {
          value: "Halogen Multi Reflector",
          feature_name: "Headlamps",
          feature_type: "2",
          visible: true,
        },
        "63c8f96a8746411432e23d4c": {
          value: "",
          feature_name: "Heavy duty door locks",
          feature_type: "2",
          visible: false,
        },
        "63c8f8148746411432e23d30": {
          value: "Y",
          feature_name: "Hill Descent Control",
          feature_type: "2",
          visible: true,
        },
        "63c8fbc98746411432e23d82": {
          value: "Y",
          feature_name: "Hill Hold Control",
          feature_type: "2",
          visible: true,
        },
        "63c8f92d8746411432e23d46": {
          value: "",
          feature_name: "Immobilizer",
          feature_type: "2",
          visible: false,
        },
        "63c8f6068746411432e23d12": {
          value: "",
          feature_name: "LED Headlamps",
          feature_type: "2",
          visible: false,
        },
        "63c8f99b8746411432e23d50": {
          value: "",
          feature_name: "Ladder Rack",
          feature_type: "2",
          visible: false,
        },
        "63c8f6718746411432e23d16": {
          value: "",
          feature_name: "Letherette + Fabric Seats",
          feature_type: "2",
          visible: false,
        },
        "63c8f9d08746411432e23d56": {
          value: "",
          feature_name: "Marine Plywood inner panel",
          feature_type: "2",
          visible: false,
        },
        "63c8f6828746411432e23d18": {
          value: "",
          feature_name: "Multi Information Display",
          feature_type: "2",
          visible: false,
        },
        "63c8fb348746411432e23d74": {
          value: "",
          feature_name: "Power Steering",
          feature_type: "2",
          visible: false,
        },
        "63c8f69f8746411432e23d1a": {
          value: "",
          feature_name: "Power Window",
          feature_type: "2",
          visible: false,
        },
        "63c8fbba8746411432e23d80": {
          value: "",
          feature_name: "Power Windows",
          feature_type: "2",
          visible: false,
        },
        "63c8fa238746411432e23d5c": {
          value: "",
          feature_name: "Rear Red Reflector",
          feature_type: "2",
          visible: false,
        },
        "63c8fb118746411432e23d70": {
          value: "",
          feature_name: "Reverse Camera",
          feature_type: "2",
          visible: false,
        },
        "63c8fa5c8746411432e23d64": {
          value: "",
          feature_name: "Reverse Sensor",
          feature_type: "2",
          visible: false,
        },
        "63c8f6518746411432e23d14": {
          value: "",
          feature_name: "Roof Rails",
          feature_type: "2",
          visible: false,
        },
        "63c8f93a8746411432e23d48": {
          value: "",
          feature_name: "Seats",
          feature_type: "2",
          visible: false,
        },
        "63c8fa888746411432e23d6a": {
          value: "",
          feature_name: "Side Mirrors",
          feature_type: "2",
          visible: false,
        },
        "63c8f94b8746411432e23d4a": {
          value: "",
          feature_name: "Sliding Glass Window",
          feature_type: "2",
          visible: false,
        },
        "63c8fa318746411432e23d5e": {
          value: "",
          feature_name: "Speaker",
          feature_type: "2",
          visible: false,
        },
        "63c8f7cd8746411432e23d2a": {
          value: "",
          feature_name: "Special Edition Standard Accessories",
          feature_type: "2",
          visible: false,
        },
        "63c8f91e8746411432e23d44": {
          value: "",
          feature_name: "Steering Lock",
          feature_type: "2",
          visible: false,
        },
        "63c8f9af8746411432e23d52": {
          value: "",
          feature_name: "Tool Box",
          feature_type: "2",
          visible: false,
        },
        "63c8fa418746411432e23d60": {
          value: "",
          feature_name: "Tumble Folding 3rd row Seats",
          feature_type: "2",
          visible: false,
        },
        "63c8f7708746411432e23d26": {
          value: "",
          feature_name: "Vinyl + Fabric Seats",
          feature_type: "2",
          visible: false,
        },
      },
      _id: "645cdc2e70292a422a1c4238",
    },
  ],
  vehicle_type: "SUV",
  video: [],
  car_product: [],
  car_topic: [],
  menu_id: "644f92b38497e0190403d877",
  image_video: "model/video/1689777402077_01_CMS_Jimny_DV_re_1MB.mp4",
  mobile_video: "model/video/1684820685383_01_CMS_JIMNY_MV_1MB.mp4",
  seo_settings: {
    title: "Jimny",
    desc: "jimny",
    keywords: "jimny",
  },
  price1: true,
  price2: false,
  price3: false,
  random_string:
    "3955fb2ac11c225f35ceca2c03e7b91e357d8b6e20200b76f5953fea92ca6969",
  video_delete: "model/video/1689232542088_01_CMS_Jimny_DV_re.mp4",
  variant_propulsion: [],
};

export const bikeBurgman = {
  _id: "63b3eba7086fa000e33ca2cb",
  __v: 0,
  brochure: "1672735648844_JimnyBrochure_compressed.pdf",
  comfortable_equipment: [
    {
      title: "Pack your gear for adventure.",
      desc: "Whether navigating the urban jungle or exploring off the beaten track, the Jimny offers ample space and practical storage features to accommodate every need.",
      image_desktop: "1672736273758_section4_desktop.webp",
      image_mobile: "1672736279733_section4_mobile.webp",
      updated_status: 0,
      status: 0,
      _id: "64539da7b4bdd03c0cb21b9a",
    },
    {
      title: "Front seat",
      desc: "<p>The front seat cushion frames are 70mm wider and 55mm taller seatbacks contribute to better shock absorption. </p>",
      image_desktop: "Front_Seat_OP1_1683020755079.jpg",
      image_mobile: "Front_Seat_OP1_1683020766450.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d9d2a36826fc72a060b",
    },
  ],
  configure: [
    {
      title: "Safeguard yourself and others.",
      desc: "Safety is a priority whether trekking through the wilderness or commuting in the city. Thanks to Suzuki's safety technology, you can always drive with peace of mind.\n",
      image_desktop: "1672736182150_section2_desktop.webp",
      image_mobile: "1672736189222_section2_mobile.webp",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "645276932a36826fc728fea3",
    },
    {
      title: "Dual front airbags",
      desc: "<p> As a supplemental restraint system to the seatbelts, the airbags contribute to mitigating the collision force inflicted on the head and chest.</p>",
      image_desktop: "406_OP_1683019704982.jpg",
      image_mobile: "406_OP_1683019712611.jpg",
      updated_keys: ["title", "desc"],
      updated_status: 0,
      status: 0,
      _id: "645234bbb4bdd03c0cb09c43",
    },
    {
      title: "Electronic stability programme®",
      desc: "<p>If the Electronic Stability Programme (ESP®) detects wheel slippage, it automatically adjust the engine torque and brakes to suppress wheel slippage and help the driver stay in directional control.</p>",
      image_desktop: "435_OP_1683019766981.jpg",
      image_mobile: "435_OP_1683019781439.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "645275be2a36826fc728fe53",
    },
  ],
  createdAt: "2023-01-03T09:00:18.467Z",
  degree_view: {
    title: "Stay focused on what matters most.",
    desc: "In the Jimny, there is little to distract you from driving. Its stylish black interior is humble yet handsome, and the controls are designed for quick and easy operation even when wearing gloves.\n",
    highlights:
      "In the Jimny, there’s little to distract you from driving. Its stylish black interior is humble yet handsome, and controls are designed for quick and easy operation even when wearing gloves.\n",
    html: '<iframe src="https://autodev.suzuki.com.ph/suv/all-new-jimny/threesixty/interior" frameborder="0" scrolling="no" style="width:100%; height:400px"></iframe>',
    cta_text: "XEM THÊM",
    cta_link:
      "/pages/luxury-and-exhibition",
    image_desktop: "1676963614148_section1_desktop2.webp",
    image_mobile: "1676963626003_section1_mobile2.webp",
  },
  deleted_images: [],
  description: "<p><br></p>",
  exterior_degree_view: {
    title: "BURGMAN STREET 125",
    desc: "Khám phá thiết kế BURGMAN Street 125 qua nhiều góc độ khác nhau. Trải nghiệm những đường nét bóng bẩy, phong cách hiện đại và cùng các chi tiết đầy tinh tế của chiếc xe tay ga đặc biệt này.",
    highlights:
      "Burgman Street, mẫu xe tay ga phong cách châu Âu lịch lãm, sang trọng hoàn toàn mới. Với sự kết hợp hoàn hảo giữa công nghệ tiên tiến và thiết kế kiểu dáng sang trọng sẽ mang đến cho khách hàng trải nghiệm khác biệt.",
    html: `<iframe style="overflow: hidden; height: 100%;" id="vinsearch" src="https://suzuki-world.com.vn/Burgman/index.html" name="myiFrame" marginwidth="0px" marginheight="0px" scrolling="no" allowfullscreen="allowfullscreen" width="100%" height="100%" frameborder="0"></iframe>`,
    cta_text: "XEM THÊM",
    cta_link:
      "/pages/luxury-and-exhibition",
    image_desktop: "1672924249924_section1_desktop1.webp",
    image_mobile: "1672924257863_section1_mobile1.webp",
  },
  images: {
    desk_small: "1672735679375_product-jimny-kineticyellow.webp",
    desk_medium: "1672735686367_product-jimny-kineticyellow.webp",
    desk_large: "1672735707003_home-1920x738_3.webp",
    desk_xl: "1672735716858_home-1920x738_3.webp",
    desk_banners: ["1672735747875_banner_desktop.webp"],
    mob_small: "1672735804601_product-jimny.webp",
    mob_medium: "1672735810194_product-jimny.webp",
    mob_large: "1672735819303_home-768x564-mob_3.webp",
    mob_xl: "1672735773120_banner_mobile.webp",
    mob_banners: ["jimny.webp_1679987529864"],
  },
  key_features: [],
  mileage: "NAA",
  model_code: "JI",
  model_name: "SATRIA F150",
  page_url: "jimny",
  performance: [
    {
      title: "Power your off-road ambitions.",
      desc: "Nothing stands in your way when driving the Jimny. It takes you wherever you want to go with unrivalled agility, precision control and powerful torque when you need it most.",
      image_desktop: "1672736235450_section3_desktop.webp",
      image_mobile: "1672736241716_section3_mobille.webp",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "645276a52a36826fc728fef3",
    },
    {
      title: "4AT (GL/GLX)",
      desc: "<p>The 4-speed automatic transmission has been renewed to match the K15B engine, with refined transmission components that reduces friction and enhanced fuel efficiency.</p>",
      image_desktop: "118_1683019875469.jpg",
      image_mobile: "118_1683019858704.jpg",
      updated_status: 0,
      status: 0,
      _id: "64539d40b4bdd03c0cb21ab8",
    },
    {
      title: "5MT (GL) ",
      desc: "<p>Based on the current model’s 5 speed manual transmission, the new Jimny’s transmission system has optimized gear ratios and better fuel efficiency.</p>",
      image_desktop: "119_1683019890028.jpg",
      image_mobile: "119_1683019899471.jpg",
      updated_keys: ["title", "desc"],
      updated_status: 0,
      status: 0,
      _id: "645235dab4bdd03c0cb09cf9",
    },
    {
      title: "4WD with low range transfer gear ",
      desc: "<p>Part-time 4WD allows switching between 4WD, for optimal off-road performance, and 2WD for better fuel economy and enhanced cornering on paved roads. 4WD low range transfer gear provides extra torque in the 4L mode for rough terrain.</p>",
      image_desktop: "120_OP_1683019987338.jpg",
      image_mobile: "120_OP_1683019963703.jpg",
      updated_status: 0,
      status: 0,
      _id: "64539d55b4bdd03c0cb21ae0",
    },
    {
      title: "Ladder frame ",
      desc: "<p>Compared to a monocoque body, it is more resistant to torsion and better suited for off-road use. With the ladder frame, the driver can drive confidently even on rough terrain.</p>",
      image_desktop: "408_1683020120950.jpg",
      image_mobile: "408_1683020113567.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d60b4bdd03c0cb21b08",
    },
    {
      title: "3-link rigid axle suspension with coil spring",
      desc: "<p>The new Jimny is equipped with long-stroke 3-link suspension that is teamed with rigid full wheel axles on both front and rear to support strong traction on uneven terrain. It’s a far more robust solution than the independent suspensions found on many of today’s SUVs.</p>",
      image_desktop: "412_OP_1683020142977.jpg",
      image_mobile: "412_OP_1683020152999.jpg",
      updated_status: 0,
      status: 0,
      _id: "64539d6ab4bdd03c0cb21b30",
    },
    {
      title: "Three angles",
      desc: "<p>The Jimny can climb over obstacles and steep hills without scrapping its bumpers or underbody, thanks to an ample approach angle (37 deg.), and ramp breakover angle (28 deg.) and departure angle (49 deg.).</p>",
      image_desktop: "414_1_1683020172906.jpg",
      image_mobile: "414_1_1683020164471.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d762a36826fc72a05a6",
    },
    {
      title: "Hill hold control",
      desc: "<p>Move upward and onward when starting on steep slopes. Even on bumpy slopes that vary on the right and left sides, this feature helps prevent rearward rolling so you can focus on controlling acceleration.</p>",
      image_desktop: "418_OP_1683020193775.jpg",
      image_mobile: "418_OP_1683020202994.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d812a36826fc72a05ce",
    },
    {
      title: "Hill descent control",
      desc: "<p>On tricky descents, with a push of a button, the system is designed to automatically apply the brakes and maintain a fixed speed so you can concentrate on steering without using the brake pedal or clutch.</p>",
      image_desktop: "419_OP_1683020281957.jpg",
      image_mobile: "419_OP_1683020270725.jpg",
      updated_keys: ["title"],
      updated_status: 0,
      status: 0,
      _id: "64539d8bb4bdd03c0cb21b6c",
    },
  ],
  published: 1,
  published_value: 3,
  retail_price: "NA",
  see_details: {
    title: "4WD with low range transfer gear.",
    desc: "At your command, the Jimny adapts to every situation. Let ALLGRIP PRO satisfy your adventurous mind with its on and off-road mastery.\n",
    cta_text: "KNOW MORE",
    cta_link:
      "https://website.suzukibusinesscloud-philippinesuat.com/pages/Jimny-detail",
    image_desktop: "1675416260666_JimnySECTION5(1).jpg",
    image_mobile: "1672736339675_section5_mobile.webp",
  },
  short_description:
    "READY TO CONQUER THE WILD\nBuilt to tackle the harshest\nweather and terrain, the Jimny goes where other vehicles fear to tread. Take on\nnature’s challenges. Unleash your spirit of adventure and get ready to explore..",
  sliders: [],
  status: 0,
  updatedAt: "2023-07-19T14:37:43.687Z",
  updated_images: [
    {
      Key: "Desk_1922X1153Exterior_1683986594465.jpg",
    },
    {
      Key: "Desk765x1920Interior_1683986597357.jpg",
    },
    {
      Key: "fileuploader/Maskgroup_1689579166831.png",
    },
  ],
  updated_status: {
    basic_details: 0,
    images: 0,
    variants: 0,
    features: 0,
    sliders: 0,
    degree_view: 0,
    degree_view_images: 0,
    configure: 0,
    performance: 0,
    see_details: 0,
    key_features: 0,
    comfortable_equipment: 0,
    exterior_degree_view: 0,
    video: 0,
    car_product: 0,
    car_topic: 0,
    menu_list: 0,
    seo_settings: 0,
  },
  variants: [
    {
      variant_name: "BURGMAN STREET 125",
      variant_code: "JI08",
      fuel_type: "GAS",
      engine_cc: "1500",
      transmission_type: "Manual",
      emission: "Euro 4",
      power: "",
      co2WltpMin: "",
      co2WltpComb: "",
      fuelConsumptionComb: "",
      fuelConsumptionMax: "",
      fuelConsumptionMin: "",
      smcVariantCode: "",
      drive: "",
      grade: "",
      description2: "",
      year: "",
      enquiry: "true",
      gearbox: "",
      propulsion: "",
      status: 2,
      colors: [
        {
          color_name: "XÁM MỜ - VÀNG ĐỒNG",
          color_code: "ZJ3",
          hex_code: "#767171",
          hex_code2 : "#7F6000",
          image: `${configuration.IMG_STATIC_URL}assets/images/burgman/Matte_Gray _Bronze_Gold.png`,
          on_road_price: "48.600.000 VND",
          status: 0,
          is_default: false,
          _id: "645df6b2372e859e942eabf3",
        },
        {
          color_name: "TRẮNG - VÀNG ĐỒNG",
          color_code: "Z2S",
          hex_code: "#EAEAEA",
          hex_code2 :"#BFAF7F",
          image: `${configuration.IMG_STATIC_URL}assets/images/burgman/White_Bronze_Gold.png`,
          on_road_price: "48.600.000 VND",
          status: 0,
          is_default: false,
          _id: "645df60d372e859e942ea56f",
        },
        {
          color_name: "ĐEN - VÀNG ĐỒNG",
          color_code: "26U",
          hex_code: "#989898",
          hex_code2 : "#BFAF7F",
          image: `${configuration.IMG_STATIC_URL}assets/images/burgman/Black_Bronze_Gold.png`,
          on_road_price: "48.600.000 VND",
          status: 0,
          is_default: false,
          _id: "645df4d970292a422a1d32ba",
        },
      ],
      features: {
        "63d77284cba94805f0b09932": {
          value: "1462",
          feature_name: "Chiều dài x rộng x cao",
          feature_type: "1",
          visible: true,
        },
        "63d773cecba94805f0b09934": {
          value: " 130 / 4,000",
          feature_name: "Maximum Torque (Nm/RPM)",
          feature_type: "1",
          visible: true,
        },
        "63d77348cba94805f0b09933": {
          value: " 100 / 6,000",
          feature_name: "Maximum output (HP/RPM)",
          feature_type: "1",
          visible: true,
        },
        "63d77525cba94805f0b09937": {
          value: "210",
          feature_name: "Minimum Ground Clearance (mm)",
          feature_type: "1",
          visible: true,
        },
        "63d77498cba94805f0b09935": {
          value: "4.9",
          feature_name: "Minimum Turning Radius (m)",
          feature_type: "1",
          visible: true,
        },
        "63d775dbcba94805f0b09939": {
          value: " 4 Person",
          feature_name: "Seating Capacity",
          feature_type: "1",
          visible: true,
        },
        "63d76ffbcba94805f0b09931": {
          value: "5-Speed Manual",
          feature_name: "Transmission Type",
          feature_type: "1",
          visible: true,
        },
        "63d77585cba94805f0b09938": {
          value: " 195/80R15",
          feature_name: "Tyres",
          feature_type: "1",
          visible: true,
        },
        "63c8f90f8746411432e23d42": {
          value: "",
          feature_name: "12V Accessory Sockets",
          feature_type: "2",
          visible: false,
        },
        "63c8f8908746411432e23d38": {
          value: "",
          feature_name: "22 Rope Hooks",
          feature_type: "2",
          visible: false,
        },
        "63c8f9e28746411432e23d58": {
          value: "",
          feature_name: "50/50 Split Full Swing Hinges Back Door with Camlock",
          feature_type: "2",
          visible: false,
        },
        "63c8fb8a8746411432e23d7a": {
          value: "",
          feature_name: "ABS",
          feature_type: "2",
          visible: false,
        },
        "63c8f7328746411432e23d20": {
          value: "",
          feature_name: "ABS with EBD",
          feature_type: "2",
          visible: false,
        },
        "63c8f8c38746411432e23d3e": {
          value: "",
          feature_name: "Air Conditioner",
          feature_type: "2",
          visible: false,
        },
        "63c8fb438746411432e23d76": {
          value: "",
          feature_name: "Air Conditioning",
          feature_type: "2",
          visible: false,
        },
        "63c8fab08746411432e23d6c": {
          value: "",
          feature_name: "Air Conditioning with Manual Controls",
          feature_type: "2",
          visible: false,
        },
        "63c8fb978746411432e23d7c": {
          value: "",
          feature_name: "Airbags",
          feature_type: "2",
          visible: false,
        },
        "63c8f9bf8746411432e23d54": {
          value: "",
          feature_name: "Aluminium Rivetless Exterior Panel",
          feature_type: "2",
          visible: false,
        },
        "63c8f8528746411432e23d34": {
          value: "",
          feature_name: "Angle Post",
          feature_type: "2",
          visible: false,
        },
        "63c8f7a18746411432e23d28": {
          value: "",
          feature_name: "Anti-Brake Lock System (ABS)",
          feature_type: "2",
          visible: false,
        },
        "63c8fa508746411432e23d62": {
          value: "",
          feature_name: "Assist Grip",
          feature_type: "2",
          visible: false,
        },
        "63c8f8b18746411432e23d3c": {
          value: "",
          feature_name: "Audio System",
          feature_type: "2",
          visible: false,
        },
        "63c8fb248746411432e23d72": {
          value: '9" Touchscreen w/o Clinometer',
          feature_name: "Audio Unit",
          feature_type: "2",
          visible: true,
        },
        "63c8f8018746411432e23d2e": {
          value: "            -",
          feature_name: "Automatic Lamps",
          feature_type: "2",
          visible: true,
        },
        "63c8f7478746411432e23d22": {
          value: "",
          feature_name: "Break Assist Function",
          feature_type: "2",
          visible: false,
        },
        "63c8f9898746411432e23d4e": {
          value: "",
          feature_name: "Ceiling Grab Handles",
          feature_type: "2",
          visible: false,
        },
        "63c8f6e28746411432e23d1c": {
          value: "",
          feature_name: "Central Door locks",
          feature_type: "2",
          visible: false,
        },
        "63c8faf38746411432e23d6e": {
          value: "",
          feature_name: "Cruise Control",
          feature_type: "2",
          visible: false,
        },
        "63c8f8fd8746411432e23d40": {
          value: "",
          feature_name: "Cup holder",
          feature_type: "2",
          visible: false,
        },
        "63c8fa698746411432e23d66": {
          value: "",
          feature_name: "Defogger",
          feature_type: "2",
          visible: false,
        },
        "63c8fa788746411432e23d68": {
          value: "",
          feature_name: "Door locks",
          feature_type: "2",
          visible: false,
        },
        "63c8f71b8746411432e23d1e": {
          value: "Y",
          feature_name: "Dual Airbags",
          feature_type: "2",
          visible: true,
        },
        "63c8f8a28746411432e23d3a": {
          value: "",
          feature_name: "Electric Power Steering",
          feature_type: "2",
          visible: false,
        },
        "63c8fba98746411432e23d7e": {
          value: "Y",
          feature_name: "Electronic Stability Program (ESP)",
          feature_type: "2",
          visible: true,
        },
        "63c8f83a8746411432e23d32": {
          value: "",
          feature_name: "Engine Auto Start Stop (EASS)",
          feature_type: "2",
          visible: false,
        },
        "63c8fb7c8746411432e23d78": {
          value: "Y",
          feature_name: "Fabric Seats",
          feature_type: "2",
          visible: true,
        },
        "63c8fa128746411432e23d5a": {
          value: "",
          feature_name: "Front and Side Clearance Lamp",
          feature_type: "2",
          visible: false,
        },
        "63c8f8698746411432e23d36": {
          value: "",
          feature_name: "Gate Type",
          feature_type: "2",
          visible: false,
        },
        "63c8f75a8746411432e23d24": {
          value: "",
          feature_name: "Halogen Multi Reflector Headlamps",
          feature_type: "2",
          visible: false,
        },
        "63c8f7ea8746411432e23d2c": {
          value: "Halogen Multi Reflector",
          feature_name: "Headlamps",
          feature_type: "2",
          visible: true,
        },
        "63c8f96a8746411432e23d4c": {
          value: "",
          feature_name: "Heavy duty door locks",
          feature_type: "2",
          visible: false,
        },
        "63c8f8148746411432e23d30": {
          value: "Y",
          feature_name: "Hill Descent Control",
          feature_type: "2",
          visible: true,
        },
        "63c8fbc98746411432e23d82": {
          value: "Y",
          feature_name: "Hill Hold Control",
          feature_type: "2",
          visible: true,
        },
        "63c8f92d8746411432e23d46": {
          value: "",
          feature_name: "Immobilizer",
          feature_type: "2",
          visible: false,
        },
        "63c8f6068746411432e23d12": {
          value: "",
          feature_name: "LED Headlamps",
          feature_type: "2",
          visible: false,
        },
        "63c8f99b8746411432e23d50": {
          value: "",
          feature_name: "Ladder Rack",
          feature_type: "2",
          visible: false,
        },
        "63c8f6718746411432e23d16": {
          value: "",
          feature_name: "Letherette + Fabric Seats",
          feature_type: "2",
          visible: false,
        },
        "63c8f9d08746411432e23d56": {
          value: "",
          feature_name: "Marine Plywood inner panel",
          feature_type: "2",
          visible: false,
        },
        "63c8f6828746411432e23d18": {
          value: "",
          feature_name: "Multi Information Display",
          feature_type: "2",
          visible: false,
        },
        "63c8fb348746411432e23d74": {
          value: "",
          feature_name: "Power Steering",
          feature_type: "2",
          visible: false,
        },
        "63c8f69f8746411432e23d1a": {
          value: "",
          feature_name: "Power Window",
          feature_type: "2",
          visible: false,
        },
        "63c8fbba8746411432e23d80": {
          value: "",
          feature_name: "Power Windows",
          feature_type: "2",
          visible: false,
        },
        "63c8fa238746411432e23d5c": {
          value: "",
          feature_name: "Rear Red Reflector",
          feature_type: "2",
          visible: false,
        },
        "63c8fb118746411432e23d70": {
          value: "",
          feature_name: "Reverse Camera",
          feature_type: "2",
          visible: false,
        },
        "63c8fa5c8746411432e23d64": {
          value: "",
          feature_name: "Reverse Sensor",
          feature_type: "2",
          visible: false,
        },
        "63c8f6518746411432e23d14": {
          value: "",
          feature_name: "Roof Rails",
          feature_type: "2",
          visible: false,
        },
        "63c8f93a8746411432e23d48": {
          value: "",
          feature_name: "Seats",
          feature_type: "2",
          visible: false,
        },
        "63c8fa888746411432e23d6a": {
          value: "",
          feature_name: "Side Mirrors",
          feature_type: "2",
          visible: false,
        },
        "63c8f94b8746411432e23d4a": {
          value: "",
          feature_name: "Sliding Glass Window",
          feature_type: "2",
          visible: false,
        },
        "63c8fa318746411432e23d5e": {
          value: "",
          feature_name: "Speaker",
          feature_type: "2",
          visible: false,
        },
        "63c8f7cd8746411432e23d2a": {
          value: "",
          feature_name: "Special Edition Standard Accessories",
          feature_type: "2",
          visible: false,
        },
        "63c8f91e8746411432e23d44": {
          value: "",
          feature_name: "Steering Lock",
          feature_type: "2",
          visible: false,
        },
        "63c8f9af8746411432e23d52": {
          value: "",
          feature_name: "Tool Box",
          feature_type: "2",
          visible: false,
        },
        "63c8fa418746411432e23d60": {
          value: "",
          feature_name: "Tumble Folding 3rd row Seats",
          feature_type: "2",
          visible: false,
        },
        "63c8f7708746411432e23d26": {
          value: "",
          feature_name: "Vinyl + Fabric Seats",
          feature_type: "2",
          visible: false,
        },
      },
      _id: "645cdc2e70292a422a1c4238",
    },
  ],
  vehicle_type: "SUV",
  video: [],
  car_product: [],
  car_topic: [],
  menu_id: "644f92b38497e0190403d877",
  image_video: "model/video/1689777402077_01_CMS_Jimny_DV_re_1MB.mp4",
  mobile_video: "model/video/1684820685383_01_CMS_JIMNY_MV_1MB.mp4",
  seo_settings: {
    title: "Jimny",
    desc: "jimny",
    keywords: "jimny",
  },
  price1: true,
  price2: false,
  price3: false,
  random_string:
    "3955fb2ac11c225f35ceca2c03e7b91e357d8b6e20200b76f5953fea92ca6969",
  video_delete: "model/video/1689232542088_01_CMS_Jimny_DV_re.mp4",
  variant_propulsion: [],
};

export const twoWheelerData = [
  {
    model_name: "RAIDER 150FI",
    model_code: "FU150FI-RAIDER",
    variants: [
      {
        variant_name: "XANH NHÁM-ĐEN",
        variant_code: "XANH NHÁM-ĐEN",
      },
      {
        variant_name: "ĐỎ ĐEN",
        variant_code: "ĐỎ ĐEN",
      },
      {
        variant_name: "NÂU-ĐEN",
        variant_code: "NÂU-ĐEN",
      },
      {
        variant_name: "XANH-ĐEN",
        variant_code: "XANH-ĐEN",
      },
      {
        variant_name: "TRẮNG-ĐỎ-ĐEN",
        variant_code: "TRẮNG-ĐỎ-ĐEN",
      },
      {
        variant_name: "XÁM-ĐEN",
        variant_code: "XÁM-ĐEN",
      },
    ],
  },
  {
    model_name: "SATRIA F150",
    model_code: "SATRIA-F150",
    variants: [
      {
        variant_name: "ĐEN TRẮNG",
        variant_code: "ĐEN TRẮNG",
      },
      {
        variant_name: "XANH-BẠC-ĐEN",
        variant_code: "XANH-BẠC-ĐEN",
      },
      {
        variant_name: "ĐEN",
        variant_code: "ĐEN",
      },
    ],
  },
  {
    model_name: "BURGMAN STREET",
    model_code: "BURGMAN",
    variants: [
      {
        variant_name: "XÁM MỜ-VÀNG ĐỒNG",
        variant_code: "XÁM MỜ-VÀNG ĐỒNG",
      },
      {
        variant_name: "TRẮNG-VÀNG ĐỒNG",
        variant_code: "TRẮNG-VÀNG ĐỒNG",
      },
      {
        variant_name: "ĐEN-VÀNG ĐỒNG",
        variant_code: "ĐEN-VÀNG ĐỒNG",
      },
    ],
  },
  {
    model_name: "V-STROM 250SX",
    model_code: "VSTROM-250SX",
    variants: [
      {
        variant_name: "CAM-ĐEN",
        variant_code: "CAM-ĐEN",
      },
      {
        variant_name: "VÀNG-ĐEN",
        variant_code: "VÀNG-ĐEN",
      },
      {
        variant_name: "ĐEN",
        variant_code: "ĐEN",
      },
    ],
  },
];