import React, { forwardRef } from "react";
import Style from "./Forms.module.css";

// SelectInput component accepts various props for customization
// eslint-disable-next-line react/display-name
const SelectInput = forwardRef(
  (
    {
      error,
      label,
      required,
      name,
      mandatory,
      items,
      value,
      placeholder,
      handleChange,
      nameKey,
      isPlaceholderDisabled,
      disabled,
      ...otherProps
    },
    ref,
  ) => {
    return (
      <>
        {/* Container for the select input and label */}
        <div className={Style.select__container}>
          {/* Label for the select input */}
          {label && (
            <label className={Style.label} htmlFor={name}>
              {label}
              {required && "*"}
            </label>
          )}

          {/* Select input */}
          <select
            className={error ? Style.inputError : Style.input}
            required={mandatory}
            disabled={disabled}
            name={name}
            onChange={handleChange}
            {...otherProps}
            ref={ref}
          >
            {/* Placeholder option */}
            <option value="" disabled={isPlaceholderDisabled}>
              {placeholder}
            </option>

            {/* Map over items and create options */}
            
            {items?.length > 0 &&
              items.map((item, index) => (
                <option
                  key={index}
                  value={JSON.stringify(item)}
                  disabled={item.disabled}
                  selected={
                    otherProps.page === "finance-page"
                      ? item?.model_name?.toLowerCase() ===
                        otherProps?.selectedValue?.toLowerCase()
                      : item.selected
                  }
                >
                  {/* Display the item's name, model_name, or the specified nameKey */}
                  {item.name || item.model_name || item[nameKey] || item.variant_name || item.methodName}
                </option>
              ))}
          </select>
          {/* Display error message if error exists */}
          <p className={Style.error}>{error}</p>
        </div>
      </>
    );
  },
);

export default SelectInput;
