import React, { useEffect, useState } from "react";
import FloatItem from "./floatitem";
import { useRouter } from "next/router";
import { formNames } from "../forms/Validation";
import { useDispatch, useSelector } from "react-redux";
import { toggleForm } from "../../redux/ActionCreator";
import DynamicForm from "../forms/dynamic-form/dynamic-form";
import { configuration } from "../../helpers/config";
import { Constants } from "../../helpers/constants";
import axios from "axios";

// data for floating bar items
const data = [
  {
    src: `${configuration.IMG_STATIC_URL}assets/images/floatbar/inquiry.svg`,
    selectedSrc: `${configuration.IMG_STATIC_URL}assets/images/floatbar/inquirySelected.svg`,
    itemName: Constants.layout.Floatbar_Title.Get_a_Quote,
    form: formNames.GET_QUOTE,
    form2w: formNames.GET_QUOTE_2W,
    isVisible: configuration.FLOAT_BAR_GET_A_QUOTE == "true",
    show2w: configuration.FLOAT_BAR_GET_A_QUOTE_2W == "true",
  },
  {
    src: `${configuration.IMG_STATIC_URL}assets/images/floatbar/testDrive.svg`,
    selectedSrc: `${configuration.IMG_STATIC_URL}assets/images/floatbar/testDriveSelected.svg`,
    itemName: Constants.layout.Floatbar_Title.Test_Drive,
    form: formNames.TEST_DRIVE,
    form2w: formNames.TEST_DRIVE_2W,
    isVisible: configuration.FLOAT_BAR_TEST_DRIVE == "true",
    show2w: configuration.FLOAT_BAR_TEST_DRIVE_2W == "true",
  },
  {
    src: `${configuration.IMG_STATIC_URL}assets/images/floatbar/brochure.svg`,
    selectedSrc: `${configuration.IMG_STATIC_URL}assets/images/floatbar/brochureSelected.svg`,
    itemName: Constants.layout.Floatbar_Title.Brochure,
    form: formNames.BROCHURE,
    form2w: formNames.BROCHURE_2W,
    isVisible: configuration.FLOAT_BAR_BROCHURE == "true",
    show2w: configuration.FLOAT_BAR_BROCHURE_2W == "true",
  },
  {
    src: `${configuration.IMG_STATIC_URL}assets/images/floatbar/inquiry.svg`,
    selectedSrc: `${configuration.IMG_STATIC_URL}assets/images/floatbar/inquirySelected.svg`,
    itemName: Constants.layout.Floatbar_Title.Inquire,
    form: formNames.ENQUIRY,
    form2w: formNames.ENQUIRY_2W,
    isVisible: configuration.FLOAT_BAR_ENQUIRY == "true",
    show2w: configuration.FLOAT_BAR_ENQUIRY_2W == "true",
  },
  {
    src: `${configuration.IMG_STATIC_URL}assets/images/floatbar/service-icon.svg`,
    selectedSrc: `${configuration.IMG_STATIC_URL}assets/images/floatbar/service-icon-selected.svg`,
    itemName: Constants.layout.Floatbar_Title.Book_a_Service,
    form: formNames.SERVICE,
    form2w: formNames.SERVICE_2W,
    isVisible: configuration.FLOAT_BAR_BOOK_SERVICE == "true",
    show2w: configuration.FLOAT_BAR_BOOK_SERVICE_2W == "true",
  },
  {
    src: `${configuration.IMG_STATIC_URL}assets/images/floatbar/inquire-icon.png`,
    selectedSrc: "",
    itemName: Constants.layout.Floatbar_Title.Contact_us,
    form: formNames.CONTACT_US,
    form2w: formNames.CONTACT_US,
    isVisible: configuration.FLOAT_BAR_CONTACT_US == "true",
    show2w: configuration.FLOAT_BAR_CONTACT_US_2W == "true",
  },
  {
    src: `${configuration.IMG_STATIC_URL}assets/images/floatbar/findDealer.svg`,
    selectedSrc: `${configuration.IMG_STATIC_URL}assets/images/floatbar/findDealerSelected.svg`,
    itemName: Constants.layout.Floatbar_Title.Dealers,
    isVisible: configuration.FLOAT_BAR_DEALER == "true",
    show2w: configuration.FLOAT_BAR_DEALER_2W == "true",
  },
];

export default function FloatBar({ formsData }) {
  const [modalIndex, setModalIndex] = useState(0);
  const [themeData, setThemeData] = useState(null);

  const router = useRouter();
  const dispatch = useDispatch();
  const LOB_VALUE = useSelector((state) => state?.app?.LobValue);

  // function to get theme data in float bar
  useEffect(() => {
    if (!LOB_VALUE) return; 
    getThemeData();
  }, [LOB_VALUE]);

  const getThemeData = async () => {
    try {
    // fetching theme data from settings api
    const { data } = await axios.get(
      `${configuration.APP_URL_CONTENTS}/settings?lob=${LOB_VALUE}`
    );
    // assigning theme data
    if (data?.length > 0) {
      setThemeData(data[0]?.theme_settings?.find((x) => x.applied === true));
    }
} catch (error) {
  console.log("Error fetching theme data:", error.message); // Handle errors appropriately
}
  };

  const ROUTE_ADDRESS = LOB_VALUE
    ? LOB_VALUE == 1
      ? "/find-dealer/4w-vehicle"
      : LOB_VALUE == 2
      ? "/find-dealer/2w-vehicle"
      : LOB_VALUE == 3
      ? "/find-dealer/marine-vehicle"
      : "/404"
    : "/404";

  return (
    <>
      {/*{formsData && formsData[0] && (*/}
      {/*  <DynamicForm*/}
      {/*    open={modalIndex === 1}*/}
      {/*    onClose={() => setModalIndex(0)}*/}
      {/*    data={formsData ? formsData[0] : []}*/}
      {/*  />*/}
      {/*)}*/}

      {LOB_VALUE !== null && (
        <div className="floating-bar z-[40]">
          <ul className="">
            {/*// render float items for every forms that needs to be visible */}
            {data.map((f, i) => {
              if (f.isVisible) {
                return (
                  <li key={i} className="lg:border-b-[1px] lg:border-b-gray">
                    <FloatItem
                      Id={"Layout" + f.itemName?.replace(/\s+/g, "")}
                      href={f.href}
                      src={f.src}
                      selectedSrc={f.selectedSrc}
                      show2w={f.show2w}
                      itemName={f.itemName}
                      onClick={() => {
                        i === data.length - 1
                          ? router.push("/find-dealer/4w-vehicle")
                          : f.show2w
                          ? null
                          : dispatch(toggleForm(f.form));
                      }}
                      form2w={f.form2w}
                      form={LOB_VALUE == 2 ? f.form2w : f.form}
                      themeData={themeData}
                    />
                  </li>
                );
              }
            })}

            {/* <li className="chat_icon">
            <a onClick={() => dispatch(toggleForm(formNames.ENQUIRY))} href="#">
              <img src={`${configuration.IMG_STATIC_URL}assets/images/floatbar/chat.png`} alt="" />
            </a>
          </li> */}
          </ul>
        </div>
      )}
    </>
  );
}
