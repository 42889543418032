import React, { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import BrandLogo from "./brand-logo";
import useSWR from "swr";
import axios from "axios";
import { configuration } from "../../helpers/config";
import Link from "next/link";
import useIsMobile from "../../hooks/useIsMobile";
import SearchBox from "./searchbox";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
// import rightArrow from "../../public/assets/images/right-arrow.svg";
import Image from "next/image";
import { Constants } from "../../helpers/constants";
import { useSelector } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Define a fetcher function that fetches data using axios
const fetcher = (url) =>
  axios({
    method: "get",
    url: url,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    origin: "*",
    withCredentials: false,
  }).then((response) => response.data);

export default function Header({menu}) {
  const [transparent, setTransparent] = useState(true);
  const [navigation, setNavigation] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedSubMenuIndex, setSelectedSubMenuIndex] = useState(null);
  const [nestedSubMenuIndex, setNestedSubMenuIndex] = useState(null);
  const mobile = useIsMobile();
  const isLobPage = useSelector(({ app }) => app.isLobPage);
  const LobValue = useSelector(({ app }) => app.LobValue);
  const LOB_VALUE = useSelector((state) => state?.app?.LobValue);
  // Effects to handle header transparency and navigation data fetching
  useEffect(() => {
    // Check if there is a hash in the URL to determine transparency
    window.location.hash !== "" && setTransparent(false);

    // Add an event listener to change transparency on scroll
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setTransparent(false);
      } else {
        setTransparent(true);
      }
    });
  }, []);

  // Fetch navigation data using useSWR hook
  const { data, error } = useSWR(
    `${configuration.APP_URL_CONTENTS}/menus`,
    fetcher
  );

  // Update the navigation state when data is fetched
  useEffect(() => {
    let navData = data?.filter(
      (item) =>
        item.key === (LobValue === null ? "landing_page_menu" : "main_menu")
    );

    if (LobValue !== null) {
      navData = navData?.filter((item) => item.lob == LobValue);
    }

    data?.length > 0 && setNavigation(navData[0]?.nestableArray);
  }, [data, LobValue]);

   // Functions to handle adding/removing 'no-scroll' class from the document body
  const handleScrollHidden = () => {
    if (typeof document !== "undefined") {
      document.body.classList.add("no-scroll");
    }
  };
  const handleScrollVisible = () => {
    if (typeof document !== "undefined") {
      document.body.classList.remove("no-scroll");
    }
  };
  return (
    <>
      <Disclosure
        as="nav"
        className={`fixed ${
          transparent
            ? "background-nav pt-0"
            : "bg-inverted pt-0 drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]"
        } h-[60px] w-full z-[3] top-0 transition-all duration-500`}
      >
        {({ open }) => (
          <>
            <div className="container-fluid">
              <div className="container relative mx-auto">
                <div className=" flex items-center justify-between h-[60px]">
                  <div className="flex items-center flex-1 flex-start md:items-stretch md:justify-start ">
                    <div className="flex items-center flex-shrink-0">
                      <div>
                        <Link href="/" className="flex items-center" passHref>
                          <BrandLogo
                            // logoSrc={"/assets/images/logo.svg"}
                            logoSrc={`${configuration.IMG_STATIC_URL}assets/images/logo.svg`}
                            width={mobile ? 120 : 130}
                            height={mobile ? 23 : 25}
                            className=" w-auto h-[25px]"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0 ">
                    {/* menus */}
                    <div className="hidden relative md:ml-6 lg:block md:after:absolute md:after:right-0 md:after:top-[50%] md:after:h-[25px] md:after:mt-[-12px] md:after:border-r-[0px] md:after:text-light-gray md:afer:border-r-light-gray right-[-13px] mr-0">
                      <div className="flex space-x-4">
                      {menu?.nestableArray?.map((item, i) => {
                          return (
                            <Menu key={i}>
                              <div
                                className={classNames(
                                  item.text &&
                                    !transparent &&
                                    "border-t-2 hover:border-t-2 hover:border-t-primary-blue",
                                  "px-2 pt-[19px] pb-[19px] border-t-2 border-t-transparent flex relative cursor-pointer group",
                                )}
                                //show submenu only if exists
                                // onMouseOver={() =>
                                //   item?.children?.length > 0
                                //     ? setSelectedIndex(i)
                                //     : false
                                // }
                                // onMouseOut={() => setSelectedIndex(null)}
                              >
                                <a
                                  id={
                                    "Header" +
                                    (item?.item_key)?.replace(/\s+/g, "")
                                  }
                                  target={
                                    item?.children?.length === 0
                                      ? item.target.includes("_")
                                        ? item.target
                                        : `_${item.target}`
                                      : undefined
                                  }
                                  className={classNames(
                                    item.text &&
                                      !transparent &&
                                      "hover:text-primary-blue",
                                    " text-sm font-suzukiBold uppercase text-default ",
                                  )}
                                  href={
                                    item?.children?.length === 0
                                      ? item.url.includes("/")
                                        ? item.url
                                        : `/${item.url}`
                                      : undefined
                                  }
                                  aria-current={item.text ? "page" : undefined}
                                >
                                  {item.text}
                                </a>
                                {item?.children?.length > 0 && (
                                  <ChevronDownIcon
                                    className="w-5 h-5 ml-1"
                                    aria-hidden="true"
                                  />
                                )}
                                {item?.children?.length > 0  && (
                                  <div
                                    className={`absolute p-3 top-[55px] bg-[#ffff] grid gap-x-3 ${
                                      i === 0 ? "left-0" : "right-0"
                                    } w-max min-w-[100px] drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] cursor-default hidden group-hover:grid`}
                                    style={{
                                      gridTemplateColumns: `${
                                        item.children.every(
                                          (x) => x.children?.length > 0,
                                        )
                                          ? `repeat(${item?.children?.length}, minmax(0, 1fr))`
                                          : `repeat(${Math.ceil(
                                              item.children.length / 4,
                                            )}, minmax(0, 1fr))`
                                      }`,
                                    }}
                                  >
                                    {item.children.map(
                                      (submenu, subMenuIndex) => (
                                        <a
                                          id={
                                            "HeaderChild" +
                                            (submenu?.item_key)?.replace(
                                              /\s+/g,
                                              ""
                                            )
                                          }
                                          key={subMenuIndex + submenu.text}
                                          target={
                                            submenu?.children?.length === 0
                                              ? submenu.target.includes("_")
                                                ? submenu.target
                                                : `_${submenu.target}`
                                              : undefined
                                          }
                                          className={`text-default font-suzukiBold m-2 w-fit ${
                                            submenu?.children?.length === 0 &&
                                            "hover:underline underline-offset-2"
                                          }`}
                                          href={
                                            submenu?.children?.length === 0
                                              ? submenu.url.includes("/")
                                                ? submenu.url
                                                : `/${submenu.url}`
                                              : undefined
                                          }
                                        >
                                          {submenu.text}
                                          {submenu?.children?.length > 0 && (
                                            <div className="mt-5">
                                              {submenu.children?.map(
                                                (nestedMenu, i) => (
                                                  <a
                                                    key={nestedMenu.text + i}
                                                    target={
                                                      nestedMenu.target.includes(
                                                        "_",
                                                      )
                                                        ? nestedMenu.target
                                                        : `_${nestedMenu.target}`
                                                    }
                                                    className="flex flex-col my-2 font-medium font-suzukiRegular hover:underline underline-offset-2 w-fit"
                                                    href={
                                                      nestedMenu.url.includes(
                                                        "/",
                                                      )
                                                        ? nestedMenu.url
                                                        : `/${nestedMenu.url}`
                                                    }
                                                  >
                                                    {nestedMenu.text}
                                                  </a>
                                                ),
                                              )}
                                            </div>
                                          )}
                                        </a>
                                      ),
                                    )}
                                  </div>
                                )}
                              </div>
                            </Menu>
                          );
                        })}
                      </div>
                    </div>
                    <Menu
                      as="div"
                      className="relative hidden ml-3 mr-3 pl-5 border-l-[1px] border-l-light-gray"
                    >
                      {/* <div>*/}
                      {/*  <Menu.Button className="flex text-md focus:outline-none">*/}
                      {/*    <span className="sr-only">Open user menu</span>*/}
                      {/*    <img*/}
                      {/*      className={`${*/}
                      {/*        transparent ? "" : "invert"*/}
                      {/*      } w-[15px] h-[16px]`}*/}
                           {/* src=`${configuration.IMG_STATIC_URL}assets/images/header/user.svg` */}
                      {/*      alt=""*/}
                      {/*    />*/}
                      {/*  </Menu.Button>*/}
                      {/*</div> */}
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-[8] w-48 py-1 mt-2 origin-top-right rounded-md shadow-lg bg-inverted ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active
                                    ? "bg-light-gray text-primary-blue"
                                    : "",
                                  "block px-4 py-2 text-sm text-gray-700 font-suzukiRegular text-default",
                                )}
                              >
                                {Constants.layout.YOUR_PROFILE}
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active
                                    ? "bg-light-gray text-primary-blue"
                                    : "",
                                  "block px-4 py-2 text-sm text-gray-700 font-suzukiRegular text-default",
                                )}
                              >
                                {Constants.layout.SETTINGS}
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active
                                    ? "bg-light-gray text-primary-blue"
                                    : "",
                                  "block px-4 py-2 text-sm text-gray-700 font-suzukiRegular text-default",
                                )}
                              >
                                {Constants.layout.SIGN_OUT}
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    {configuration.SHOW_SEARCH == "true" &&
                      !isLobPage &&
                      LOB_VALUE !== null && (
                        <SearchBox transparentInner={transparent} />
                      )}
                  </div>
                  <div className="inset-y-0 right-0 flex items-center lg:hidden ">
                    {/* Mobile menu button*/}
                    <Disclosure.Button
                      className={`${
                        transparent ? "" : "text-default"
                      } inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:bg-gray-700 hover:text-white focus:outline-none`}
                    >
                      <span className="sr-only">
                        {Constants.layout.OPEN_MAIN_MENU}
                      </span>
                      {open ? handleScrollHidden() : handleScrollVisible()}
                      {open ? (
                        <XMarkIcon
                          className="block w-6 h-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block w-6 h-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="relative z-20 transition-all lg:hidden bg-primary-blue ">
              <div className="overflow-y-auto pb-3 pt-[55px] space-y-1 fixed right-0 w-[90%] h-[100vh] md:h-[90vh] bg-inverted">
                <div className="mx-0 h-screen">
                {menu?.nestableArray?.length > 0 ? (
                  menu?.nestableArray?.map((item, index) => (
                      <>
                        <a
                          as="a"
                          target={
                            item?.children?.length === 0
                              ? item.target.includes("_")
                                ? item.target
                                : `_${item.target}`
                              : undefined
                          }
                          href={
                            item?.children?.length === 0
                              ? item.url.includes("/")
                                ? item.url
                                : `/${item.url}`
                              : undefined
                          }
                          key={index}
                          className={`flex justify-between items-center mx-7 ${
                            selectedSubMenuIndex === index ? "mb-4" : "mb-8"
                          }`}
                        >
                          <div
                            className={classNames(
                              selectedSubMenuIndex === index
                                ? "text-primary-blue"
                                : "text-defaut-color",
                              `menuLink-header block font-suzukiBold uppercase text-mobileMenu mobile-menu z-20 cursor-pointer relative`,
                            )}
                            aria-current={item.text ? "page" : undefined}
                          >
                            <div>{item.text}</div>
                          </div>
                          <div>
                            {
                              //display dropdown icon if it has submenu
                              item.children.length > 0 ? (
                                //change dropdown icon when opened
                                selectedSubMenuIndex === index ? (
                                  <ChevronUpIcon
                                    onClick={() => {
                                      setSelectedSubMenuIndex(null);
                                      setNestedSubMenuIndex(null);
                                    }}
                                    className="w-5 h-5 ml-1"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ChevronDownIcon
                                    onClick={() => {
                                      setSelectedSubMenuIndex(index);
                                      setNestedSubMenuIndex(null);
                                    }}
                                    className="w-5 h-5 ml-1"
                                    aria-hidden="true"
                                  />
                                )
                              ) : (
                                <Image
                                  src={`${configuration.IMG_STATIC_URL}assets/images/right-arrow.svg`}
                                  width={20}
                                  height={12}
                                  alt=""
                                />
                              )
                            }
                          </div>
                        </a>
                        {selectedSubMenuIndex === index && (
                          <div className="w-auto mb-6 submenu-selected">
                            {item?.children?.map((subMenu, i) => (
                              <div
                                key={subMenu.text + i}
                                className={`${
                                  nestedSubMenuIndex === i &&
                                  subMenu?.children.length > 0 &&
                                  "bg-[#D9D9D933]"
                                } px-7 py-4 `}
                              >
                                <a
                                  className={`flex justify-between items-center ${
                                    i === 0 && "mt-3"
                                  } ${
                                    i + 1 === item?.children.length && "mb-2"
                                  }`}
                                  target={
                                    subMenu?.children?.length === 0
                                      ? subMenu.target.includes("_")
                                        ? subMenu.target
                                        : `_${subMenu.target}`
                                      : undefined
                                  }
                                  href={
                                    subMenu?.children?.length === 0
                                      ? subMenu.url.includes("/")
                                        ? subMenu.url
                                        : `/${subMenu.url}`
                                      : undefined
                                  }
                                >
                                  <div className="uppercase font-suzukiBold text-default">
                                    {subMenu?.text}
                                  </div>
                                  {
                                    //display dropdown icon if it has nested submenu
                                    subMenu?.children.length > 0 ? (
                                      //change dropdown icon when opened
                                      nestedSubMenuIndex === i ? (
                                        <ChevronUpIcon
                                          onClick={() =>
                                            setNestedSubMenuIndex(null)
                                          }
                                          className="w-5 h-5 ml-1"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <ChevronDownIcon
                                          onClick={() =>
                                            setNestedSubMenuIndex(i)
                                          }
                                          className="w-5 h-5 ml-1"
                                          aria-hidden="true"
                                        />
                                      )
                                    ) : (
                                      <Image
                                        src={`${configuration.IMG_STATIC_URL}assets/images/right-arrow.svg`}
                                        width={20}
                                        height={12}
                                        alt=""
                                      />
                                    )
                                  }
                                </a>
                                {/* nested submenu content */}
                                {subMenu?.children.length > 0 &&
                                  nestedSubMenuIndex === i && (
                                    <div className="py-1 pl-5 rounded-sm">
                                      {subMenu?.children?.map(
                                        (nestedSubmenu, x) => (
                                          <a
                                            className="flex items-center justify-between mt-4"
                                            key={nestedSubmenu.text + x}
                                            target={
                                              nestedSubmenu.target.includes("_")
                                                ? nestedSubmenu.target
                                                : `_${nestedSubmenu.target}`
                                            }
                                            href={
                                              nestedSubmenu.url.includes("/")
                                                ? nestedSubmenu.url
                                                : `/${nestedSubmenu.url}`
                                            }
                                          >
                                            <div className="font-suzukiRegular">
                                              {nestedSubmenu.text}
                                            </div>
                                            <Image
                                              src={`${configuration.IMG_STATIC_URL}assets/images/right-arrow.svg`}
                                              width={20}
                                              height={12}
                                              alt=""
                                            />
                                          </a>
                                        ),
                                      )}
                                    </div>
                                  )}
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    ))
                  ) : (
                    <div>
                      <h3>{Constants.generic.DATA_NOT_FOUND}</h3>
                    </div>
                  )}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
